import AppsNavigation from '../../../../containers/Sidebar/AppsNavigation';
import { LabelMainMenu } from './LabelMainMenu';
import _ from 'lodash';

const options = {
  mode: 'inline',
  style: 'default',
  theme: 'light',
  items: [
    {
      key: 'checkin',
      label: (
        <LabelMainMenu icon="icon-timepicker" label={<AppsNavigation />} />
      ),
    },
    {
      key: 'settings',
      label: (
        <LabelMainMenu
          icon="icon-setting"
          label="Configuraciones"
          route="/dashboard/configuraciones"
        />
      ),
    },
    {
      key: 'dairy',
      label: (
        <LabelMainMenu
          icon="icon-calendar"
          label="Agenda"
          route="/dashboard/agenda"
        />
      ),
    },
    {
      key: 'sale_point',
      label: (
        <LabelMainMenu
          icon="icon-pricing-table"
          label="Punto de venta"
          externalRoute={process.env.REACT_APP_POINT_OF_SALE}
          sendToken={true}
        />
      ),
    },
    {
      key: 'clients',
      label: (
        <LabelMainMenu
          icon="icon-wall"
          label="Clientes"
          route="/dashboard/clientes"
        />
      ),
    },
    {
      key: 'users',
      label: (
        <LabelMainMenu
          icon="icon-contacts"
          label="Usuarios"
          route="/dashboard/personal"
        />
      ),
    },
    {
      label: <LabelMainMenu icon="icon-company" label="Administración" />,
      children: [
        {
          key: 'medic_comission',
          label: (
            <LabelMainMenu
              label="C. Médicas"
              route="/dashboard/comisiones-medicas"
            />
          ),
        },
        {
          key: 'cosmetologic_comission',
          label: (
            <LabelMainMenu
              label="C. Cosmetológicas"
              route="/dashboard/comisiones-cosmetologicas"
            />
          ),
        },
        {
          key: 'rentability',
          label: <LabelMainMenu label="Reportes" route="/dashboard/reportes" />,
        },
        {
          key: 'branch_cut',
          label: (
            <LabelMainMenu
              label="Cortes por sucursal"
              route="/dashboard/corte-sucursal"
            />
          ),
        },
        {
          key: 'branch_sales',
          label: (
            <LabelMainMenu
              label="Ventas en sucursal"
              route="/dashboard/ventas-en-sucursal"
            />
          ),
        },
        {
          key: 'online_sales',
          label: (
            <LabelMainMenu
              label="Ventas en linea"
              route="/dashboard/ventas-en-linea"
            />
          ),
        },
        {
          key: 'app_sales',
          label: (
            <LabelMainMenu
              label="Ventas en app"
              route="/dashboard/ventas-en-app"
            />
          ),
        },
        {
          key: 'branch_cost',
          label: <LabelMainMenu label="Gastos por sucursal" />,
        },
      ],
    },
    {
      label: <LabelMainMenu icon="icon-ckeditor" label="Inventario" />,
      children: [
        {
          key: 'history',
          label: (
            <LabelMainMenu label="Historial" route="/dashboard/historial" />
          ),
        },
        {
          key: 'purchaseOrder',
          label: (
            <LabelMainMenu
              label="Orden de compra"
              route="/dashboard/ordenes-de-compra"
            />
          ),
        },
        {
          key: 'saleOrder',
          label: (
            <LabelMainMenu
              label="Orden de venta"
              route="/dashboard/ordenes-de-venta"
            />
          ),
        },
        {
          key: 'providers',
          label: (
            <LabelMainMenu label="Proveedores" route="/dashboard/proveedores" />
          ),
        },
        {
          key: 'transfers',
          label: (
            <LabelMainMenu
              label="Transferencias"
              route="/dashboard/transferencias"
            />
          ),
        },
        {
          key: 'assortmentOrder',
          label: (
            <LabelMainMenu
              label="Resurtido"
              route="/dashboard/ordenes-de-surtido"
            />
          ),
        },
        {
          key: 'inventory',
          label: <LabelMainMenu label="Stock" route="/dashboard/inventario" />,
        },
        {
          key: 'mermasCaducidades',
          label: <LabelMainMenu label="Mermas" />,
        },
        {
          key: 'tipoEquipos',
          label: <LabelMainMenu label="Tipo de equipos" />,
        },
      ],
    },
    {
      label: <LabelMainMenu icon="icon-chart" label="Mercadotecnia" />,
      children: [
        {
          key: 'treatments',
          label: (
            <LabelMainMenu
              label="Tratamientos"
              route="/dashboard/tratamientos"
            />
          ),
        },
        {
          key: 'packages',
          label: <LabelMainMenu label="Paquetes" route="/dashboard/paquetes" />,
        },
        {
          key: 'promotions',
          label: (
            <LabelMainMenu label="Promociones" route="/dashboard/promociones" />
          ),
        },
        {
          key: 'loyalty_system',
          label: (
            <LabelMainMenu
              label="Sistema de lealtad"
              route="/dashboard/sistema-de-lealtad"
            />
          ),
        },
        {
          key: 'members',
          label: <LabelMainMenu label="Miembros" route="/dashboard/miembros" />,
        },
        {
          key: 'miss_you',
          label: (
            <LabelMainMenu label="We miss you" route="/dashboard/we-miss-you" />
          ),
        },
        {
          key: 'appointment',
          label: (
            <LabelMainMenu
              label="Bitácora de citas"
              route="/dashboard/bitacora"
            />
          ),
        },
        {
          key: 'coupons',
          label: <LabelMainMenu label="Cupones" route="/dashboard/cupones" />,
        },
      ],
    },
    {
      label: <LabelMainMenu icon="icon-table" label="Reportes" />,
      children: [
        {
          key: 'reporteMercadotecnia',
          label: <LabelMainMenu label="Mercadotecnia" />,
        },
        {
          key: 'reporteAdministracion',
          label: <LabelMainMenu label="Administración" />,
        },
        {
          key: 'reporteRecursosHumanos',
          label: <LabelMainMenu label="Recursos Humanos" />,
        },
        {
          key: 'reporteLogistica',
          label: <LabelMainMenu label="Logística" />,
        },
      ],
    },
    {
      label: <LabelMainMenu icon="icon-files" label="Catálogos" />,
      children: [
        {
          key: 'appliances',
          label: (
            <LabelMainMenu
              label="Aparatos"
              route="/dashboard/aparatos-sucursal"
            />
          ),
        },
        {
          key: 'branch',
          label: (
            <LabelMainMenu label="Sucursales" route="/dashboard/sucursales" />
          ),
        },
        {
          key: 'products',
          label: (
            <LabelMainMenu label="Productos" route="/dashboard/productos" />
          ),
        },
        {
          key: 'cabain',
          label: <LabelMainMenu label="Cabinas" route="/dashboard/cabinas" />,
        },
        {
          key: 'categories',
          label: (
            <LabelMainMenu label="Categorías" route="/dashboard/categorias" />
          ),
        },
      ],
    },
  ],
};

const getOptions = (privileges) => {
  const modulesWithAccess = privileges
    ? Object.getOwnPropertyNames(privileges)
    : [];
  const defaultOptions = ['dashboard']; // Necesitamos revisar los permisos de estos
  const optionsWithAccess = [];

  const copyOptions = _.cloneDeep(options);
  copyOptions.items.forEach((element) => {
    let childrens = [];
    element?.children?.forEach((item) => {
      if (
        modulesWithAccess.includes(item.key) ||
        defaultOptions.includes(item.key)
      )
        childrens.push(item);
    });

    if (
      element?.key == null ||
      modulesWithAccess.includes(element.key) ||
      defaultOptions.includes(element.key)
    ) {
      delete element.children;
      if (childrens.length > 0) {
        element.children = childrens;
      }

      if (element.key !== undefined || element?.children) {
        optionsWithAccess.push(element);
      }
    }
  });

  return {
    mode: options.mode,
    style: options.style,
    theme: options.theme,
    items: optionsWithAccess,
  };
};

export default getOptions;
