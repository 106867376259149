import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useCustomModal } from '../../../../hooks/useCustomModal';
import { getCosmetologyCommissions } from '../../../../appRedux/actions';
import {
  commissionConst,
  filterNoApprove,
  filters,
  filtersMedical,
  searchFiltersCosmetology,
  searchInputInitialValues,
} from '../../../../constants/Commissions.const';
import { ListTable, ModalView, TableFilters } from '../../../uiComponents';
import { formatPrice } from '../../../../util/formatPrice';
import { mainBranch } from '../../../../constants/Branch.const';
import { Button } from 'antd';
import { CosmetologyCommissionModalBody } from '../CosmetologyCommissionModalBody';
import { stringDate, stringTime } from '../../../../util';
import { roleConst } from '../../../../constants';

export const CommissionComestology = () => {
  const { cosmetologic_comission: cosmetologyPrivileges = [], role } =
    useSelector((state) => state.userAuth);
  const { branchId: loggedBranchId, branch: branchLogged } = useSelector(
    (state) => state.userAuth
  );
  const [user, setUser] = useState();
  const [title, setTitle] = useState({});
  const { modal, closeModal } = useCustomModal();
  const [child, setChild] = useState();
  const getCommissions = getCosmetologyCommissions;
  const approvePrivilege = cosmetologyPrivileges.includes('approve');
  let applicabeFilter;

  if (roleConst.cosmetologist === role) {
    applicabeFilter = filtersMedical;
  } else if (!approvePrivilege) {
    applicabeFilter = filterNoApprove;
  } else {
    applicabeFilter = filters;
  }

  const onModalClick = (userId, user) => {
    setUser(user);
    setTitle(
      `${user?.name} ${user?.lastName} ${user?.motherLastName} - ${commissionConst.COSMETOLOGY_MODAL_TITLE}`
    );
    setChild(<CosmetologyCommissionModalBody id={userId} />);
    modal();
  };

  const columns = [
    {
      title: 'No. de empleado',
      sorter: true,
      dataIndex: 'sellerNumber',
      key: 'employeeNumber',
      render: (_, { user, seller }) =>
        roleConst.cosmetologists === role ? (
          seller?.kardex?.employeeNumber
        ) : (
          <Button
            type="link"
            htmlType="button"
            onClick={() => {
              const selectedUser = seller;
              onModalClick(selectedUser?.id, undefined, selectedUser);
            }}
          >
            {seller?.kardex?.employeeNumber}
          </Button>
        ),
    },
    {
      title: 'Nombre',
      sorter: true,
      dataIndex: 'seller',
      key: 'name',
      render: (user) => user?.name,
    },
    {
      title: 'Apellido paterno',
      sorter: true,
      dataIndex: 'lastName',
      key: 'lastName',
      render: (_, data) => data?.seller?.lastName,
    },
    {
      title: 'Apellido materno',
      sorter: true,
      dataIndex: 'motherLastName',
      key: 'motherLastName',
      render: (_, data) => data?.seller?.motherLastName,
    },
    {
      title: 'Comisión',
      dataIndex: 'commission',
      key: 'commission',
      render: (commission) => formatPrice(commission),
    },
    {
      title: 'Ventas',
      sorter: true,
      dataIndex: 'total',
      key: 'total',
      render: (total) => formatPrice(total),
    },
  ];

  if (roleConst.cosmetologist === role) {
    columns.push(
      {
        title: 'Fecha',
        dataIndex: 'created_at',
        key: 'created_at',
        render: (dateTime) => {
          const [date] = dateTime.split('T');
          return stringDate(date);
        },
      },
      {
        title: 'Hora',
        dataIndex: 'created_at',
        key: 'created_at',
        render: (dateTime) => {
          const [, time] = dateTime.split('T');
          return stringTime(time);
        },
      }
    );
  }

  return (
    <>
      <TableFilters
        privileges={cosmetologyPrivileges}
        searchFilters={searchFiltersCosmetology}
        filters={applicabeFilter}
        searchInputInitialValues={searchInputInitialValues}
        loggedBranchId={loggedBranchId}
        initialParams={
          !approvePrivilege && branchLogged !== mainBranch
            ? { branchId: loggedBranchId }
            : undefined
        }
      />
      <ListTable
        columns={columns}
        name={'Comisiones'}
        getDataFunction={getCommissions}
      />
      <ModalView title={title} width={1000}>
        {child}
      </ModalView>
    </>
  );
};
