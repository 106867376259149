import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, DatePicker, Form, Row, Space } from 'antd';
import moment from 'moment';
import {
  getCosmetologists,
  getDoctors,
  getSubsidiaries,
} from '../../../../appRedux/actions';
import { setFiltersData } from '../../../../appRedux/actions/General';
import {
  buttonLabel,
  dateFormat,
  formConfig,
  placeHolders,
  profitabilityOptions,
  reportFilter,
  reportLabel,
  reportOptions,
} from '../../../../constants';
import { CustomSelect } from '../../../uiComponents';
import { GeneralInventoryReport } from '../GeneralInventoryReport';
import { StockByBranchReport } from '../StockByBranchReport';
import { GeneralExpensesReport } from '../GeneralExpensesReport';
import { ExpensesbyBranchReport } from '../ExpensesByBranchReport';
import { GeneralCustomerReport } from '../GeneralCustomerReport';
import { GeneralAppointmentReport } from '../GeneralAppointmentReport';
import { OccupancyPercentageReportByBranch } from '../OccupancyPercentageReportByBranch';
import { GeneralCosmetologicFeesReport } from '../GeneralCosmetologicFeesReport';
import { CosmetologicFeesByCosmetologistReport } from '../CosmetologicFeesByCosmetologist';
import { MedicalCommissionsByBranchReport } from '../MedicalCommissionByBranchReport';
import { GeneralShcedulAppointments } from '../GeneralShcedulAppointments';
import { ComissionMedicalIndividualReport } from '../IndividualCommissionMedicalReport';
import { DailySalesReport } from '../DailySalesReport';
import { MonthlySales } from '../MonthySales';
import { DailyCashClosingReport } from '../DailyCashClosingReport';
import { ProfitabilityByBranchReport } from '../ProfitabilityByBranchReport';

export const ReportsFilters = ({ searchInputInitialValues, getSelected }) => {
  const dispatch = useDispatch();
  const { subsidiaries } = useSelector((state) => state.subsidiary);
  const { cosmetologists, doctors } = useSelector((state) => state.user);
  const { filtersData } = useSelector((state) => state.generalStates);
  const [filters, setFilters] = useState([
    reportFilter.year,
    reportFilter.branch,
    reportFilter.profitability,
  ]);
  const [form] = Form.useForm();
  const { RangePicker } = DatePicker;

  const setReport = (report) => {
    switch (report) {
      case reportLabel.generalInventory:
        getSelected(<GeneralInventoryReport />);
        break;
      case reportLabel.branchInventory:
        getSelected(<StockByBranchReport />);
        setFilters([reportFilter.branch]);
        break;
      case reportLabel.productConsumption:
        getSelected(<GeneralExpensesReport />);
        setFilters([reportFilter.month]);
        break;
      case reportLabel.productConsumptionBranch:
        getSelected(<ExpensesbyBranchReport />);
        setFilters([reportFilter.branch, reportFilter.month]);
        break;
      case reportLabel.generalCustomer:
        getSelected(<GeneralCustomerReport />);
        setFilters([reportFilter.dates]);
        break;
      case reportLabel.generalAppointment:
        getSelected(<GeneralAppointmentReport />);
        setFilters([reportFilter.dates]);
        break;
      case reportLabel.generalCosmetologicFees:
        getSelected(<GeneralCosmetologicFeesReport />);
        setFilters([reportFilter.dates]);
        break;
      case reportLabel.cosmetologicFeesByCosmetologist:
        getSelected(<CosmetologicFeesByCosmetologistReport />);
        setFilters([reportFilter.dates, reportFilter.cosmetologist]);
        break;
      case reportLabel.medicalCommissionsByBranchReport:
        getSelected(<MedicalCommissionsByBranchReport />);
        setFilters([reportFilter.branch, reportFilter.dates]);
        break;
      case reportLabel.generalShcedulAppointments:
        getSelected(<GeneralShcedulAppointments />);
        setFilters([reportFilter.dates]);
        break;
      case reportLabel.individualCommissionMedical:
        getSelected(<ComissionMedicalIndividualReport />);
        setFilters([reportFilter.dates, reportFilter.medic]);
        break;
      case reportLabel.occupancyPercentageByBranch:
        getSelected(<OccupancyPercentageReportByBranch />);
        setFilters([reportFilter.dates]);
        break;
      case reportLabel.dailySalesReport:
        getSelected(<DailySalesReport />);
        setFilters([reportFilter.month, reportFilter.branch]);
        break;
      case reportLabel.monthlySales:
        getSelected(<MonthlySales />);
        setFilters([reportFilter.year, reportFilter.branch]);
        break;
      case reportLabel.dailyCashClosingReport:
        getSelected(<DailyCashClosingReport />);
        setFilters([reportFilter.dates]);
        break;
      case reportLabel.profitabilityReport:
        getSelected(<ProfitabilityByBranchReport />);
        setFilters([
          reportFilter.year,
          reportFilter.branch,
          reportFilter.profitability,
        ]);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (filters.includes(reportFilter.branch))
      dispatch(getSubsidiaries({ status: 1 }));

    if (filters.includes(reportFilter.cosmetologist))
      dispatch(getCosmetologists());

    if (filters.includes(reportFilter.medic)) dispatch(getDoctors());
  }, [dispatch, filters]);

  useEffect(() => {
    form.setFieldsValue({ selectedReport: reportLabel.profitabilityReport });
  }, [form]);

  useEffect(() => {
    if (filters.includes(reportFilter.year))
      form.setFieldsValue({ year: moment() });
    if (filters.includes(reportFilter.month)) {
      form.setFieldsValue({
        month: filtersData?.month ? moment(filtersData?.month) : moment(),
      });
    }
    if (filters.includes(reportFilter.branch)) {
      form.setFieldsValue({
        branchId: filtersData?.branchId ?? subsidiaries[2]?.id,
      });
    }
    if (filters.includes(reportFilter.dates)) {
      form.setFieldsValue({
        dates: [moment().startOf('month'), moment()],
      });
    }
    if (filters.includes(reportFilter.cosmetologist))
      form.setFieldsValue({ userId: cosmetologists[0]?.id });
    if (filters.includes(reportFilter.medic))
      form.setFieldsValue({ userId: doctors[0]?.id });
    if (filters.includes(reportFilter.profitability))
      form.setFieldsValue({ type: profitabilityOptions[0].value });
  }, [filters, form, subsidiaries, cosmetologists, doctors, filtersData]);

  useEffect(() => {
    if (filters.includes(reportFilter.month))
      form.setFieldsValue({ month: moment() });
  }, [filters, form]);

  const onSubmit = (values) => {
    if (values?.year) values.year = moment(values?.year).format('YYYY');
    if (values?.month) values.month = moment(values?.month).format('YYYY-MM');

    if (values.dates)
      values.dates = values.dates.map((date) =>
        moment(date)?.format(dateFormat)
      );

    dispatch(setFiltersData(values));
  };

  return (
    <Row justify="end">
      <Form
        {...formConfig}
        onFinish={onSubmit}
        form={form}
        initialValues={searchInputInitialValues}
      >
        <Space>
          <Form.Item name={'selectedReport'} style={{ minWidth: '300px' }}>
            <CustomSelect
              options={reportOptions}
              onChange={setReport}
            ></CustomSelect>
          </Form.Item>

          {filters.includes(reportFilter.cosmetologist) && (
            <Form.Item name="userId">
              <CustomSelect
                placeholder={placeHolders.cosmetologist}
                style={{ minWidth: '200px' }}
                options={cosmetologists?.map(
                  ({ id, name, lastName, motherLastName }) => ({
                    value: id,
                    label: `${name} ${lastName} ${motherLastName}`,
                  })
                )}
              ></CustomSelect>
            </Form.Item>
          )}

          {filters.includes(reportFilter.dates) && (
            <Form.Item name="dates">
              <RangePicker format={dateFormat} />
            </Form.Item>
          )}

          {filters.includes(reportFilter.branch) && (
            <Form.Item name="branchId">
              <CustomSelect
                placeholder={placeHolders.branch}
                style={{ minWidth: '200px' }}
                options={subsidiaries?.map(({ id, name }) => ({
                  value: id,
                  label: name,
                }))}
              ></CustomSelect>
            </Form.Item>
          )}

          {filters.includes(reportFilter.month) && (
            <Form.Item name="month">
              <DatePicker picker="month" format={'YYYY-MM'} />
            </Form.Item>
          )}

          {filters.includes(reportFilter.year) && (
            <Form.Item name="year">
              <DatePicker picker="year" format={'YYYY'} />
            </Form.Item>
          )}

          {filters.includes(reportFilter.medic) && (
            <Form.Item name="userId">
              <CustomSelect
                placeholder={placeHolders.medic}
                style={{ minWidth: '200px' }}
                options={doctors?.map(
                  ({ id, name, lastName, motherLastName }) => ({
                    value: id,
                    label: `${name} ${lastName} ${motherLastName}`,
                  })
                )}
              ></CustomSelect>
            </Form.Item>
          )}

          {filters.includes(reportFilter.profitability) && (
            <Form.Item name="type">
              <CustomSelect
                placeholder={placeHolders.profitability}
                style={{ minWidth: '200px' }}
                options={profitabilityOptions}
              ></CustomSelect>
            </Form.Item>
          )}

          <Form.Item>
            <Button type="primary" className="btn-form" htmlType="submit">
              {buttonLabel.search}
            </Button>
          </Form.Item>
        </Space>
      </Form>
    </Row>
  );
};
