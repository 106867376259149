import { queryPathBuilder } from '../../util/queryPathBuilder';
import {
  GET_MEDICAL_COMMISSIONS,
  GET_MEDICAL_COMMISSION_BY_USER,
  UPDATE_COMMISSION_AGENT,
} from '../types';
import { get, patch } from './Action';

const basePath = 'commission';

// Get Medical Commissions
export const getMedicalCommissions = (params) => async (dispatch) => {
  const queryParams = queryPathBuilder(params);
  return await dispatch(
    get(`${basePath}/${queryParams}`, getMedicalCommissionsDS, true)
  );
};

// Get Medical Commissions Commission By User
export const getMedicalCommissionsByUser =
  (id, { params }) =>
  async (dispatch) => {
    const queryParams = queryPathBuilder(params);
    return await dispatch(
      get(`${basePath}/${id}/user${queryParams}`, getMedicalCommissionsByUserDS)
    );
  };

// Update commission agent
export const updateCommissionAgent =
  (appointmentId, data) => async (dispatch) => {
    return await dispatch(
      patch(
        `${basePath}/${appointmentId}/update-commission-agent`,
        data,
        updateCommissionAgentDS
      )
    );
  };

const getMedicalCommissionsDS = (commissions) => ({
  type: GET_MEDICAL_COMMISSIONS,
  payload: commissions.items,
});

const getMedicalCommissionsByUserDS = (commission) => ({
  type: GET_MEDICAL_COMMISSION_BY_USER,
  payload: commission,
});

const updateCommissionAgentDS = (data) => ({
  type: UPDATE_COMMISSION_AGENT,
  payload: data,
});
