import {
  CloudDownloadOutlined,
  DeleteOutlined,
  EditOutlined,
} from '@ant-design/icons';

export const assortmentOrderConst = {
  header: 'Orden de resurtido',
  prefix: 'OR',
};

export const orderTypes = [
  { value: 'Orden de apertura', label: 'Orden de apertura' },
  { value: 'Ordinario de venta', label: 'Ordinario de venta' },
  { value: 'Ordinario general', label: 'Ordinario general' },
  { value: 'Extraordinario de venta', label: 'Extraordinario de venta' },
  { value: 'Extraordinario general', label: 'Extraordinario general' },
];

export const searchFilters = [
  { value: 'order.order_number', label: 'Orden' },
  { value: 'orderBy.name', label: 'Creó' },
  { value: 'approvedBy.name', label: 'Aprobó' },
  { value: 'acceptedBy.name', label: 'Aceptó' },
  { value: 'rejectedBy.name', label: 'Rechazó' },
];

export const filters = {
  search: true,
  orderStatus: true,
  orderType: true,
  branches: true,
};

export const searchInputInitialValues = {
  search: { filter: 'order.order_number' },
};

export const assortmentTableMenuOpt = () => {
  return [
    {
      label: 'Editar',
      key: 'edit',
      icon: <EditOutlined />,
      privilege: 'edit',
    },
    {
      label: 'Eliminar',
      key: 'delete',
      icon: <DeleteOutlined />,
      privilege: 'delete',
    },
    {
      label: 'PDF',
      key: 'pdf',
      icon: <CloudDownloadOutlined />,
      privilege: 'reports',
    },
  ];
};


export const assortmentStatusConst = {
  inTransit: 'En transito',
  inProcess: 'En proceso',
  deleted: 'Eliminada',
  rejectedAt: 'Rechazada',
};

export const notDeleteableStatus = [
  assortmentStatusConst.inTransit,
  assortmentStatusConst.deleted,
  assortmentStatusConst.rejectedAt,
];
