import { useEffect, useRef, useState } from 'react';
import { useDownloadExcel } from 'react-export-table-to-excel';
import { useDispatch, useSelector } from 'react-redux';
import { getStockByBranch } from '../../../../appRedux/actions/Reports';
import { formatPrice } from '../../../../util/formatPrice';
import { ReportTableHeader } from '../../../uiComponents/ReportTableHeader';
import { reportDate } from '../../../../util';
import { ExportButton } from '../../../uiComponents/ExportButton';
import { formatFileName } from '../../../../util/strings';
import { branchStockReportConst } from '../../../../constants';
import { useLocation } from 'react-router-dom';

export const StockByBranchReport = () => {
  const location = useLocation();
  const tableRef = useRef(null);
  const dispatch = useDispatch();
  const { stockByBranch } = useSelector((state) => state.reports);
  const { fullname, role, branchId } = useSelector((state) => state.userAuth);
  const { filtersData } = useSelector((state) => state.generalStates);
  const [totalStock, setTotalStock] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [header, setHeader] = useState([]);
  const [isManager, setIsManager] = useState(true);
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: formatFileName(branchStockReportConst.title),
    sheet: branchStockReportConst.title,
  });
  const stockSection = '/dashboard/inventario';

  useEffect(() => {
    if (filtersData.branchId)
      dispatch(getStockByBranch({ branchId: filtersData.branchId }));
  }, [dispatch, filtersData.branchId]);

  useEffect(() => {
    if (isManager) {
      dispatch(getStockByBranch({ branchId }));
    }
  }, [branchId, dispatch, isManager]);

  useEffect(() => {
    let quantity = 0;
    let amount = 0;
    for (const stock of stockByBranch) {
      quantity += Number(stock.quantity);
      amount += Number(stock.unitPrice * stock.quantity);
    }
    setTotalStock(quantity);
    setTotalAmount(amount);
    setIsManager(role === 'Sucursal Gerente');

    const header = branchStockReportConst.header.filter((item) => {
      if (role === 'Sucursal Gerente')
        return !['PRECIO UNITARIO', 'SUB TOTAL'].includes(item);
      return item;
    });
    setHeader(header);
  }, [stockByBranch, role]);

  useEffect(() => {
    if (stockByBranch) {
      stockByBranch.sort(function (a, b) {
        return (
          a.category.localeCompare(b.category) || a.sku.localeCompare(b.sku)
        );
      });
    }
  }, [stockByBranch]);

  return (
    <div style={{ width: '100%' }}>
      <ExportButton
        onDownload={onDownload}
        label={branchStockReportConst.title}
      />

      <table
        ref={tableRef}
        style={{
          overflowX: 'scroll',
          margin: '0 auto',
          display: location.pathname === stockSection ? 'none' : 'block',
        }}
      >
        <ReportTableHeader
          user={fullname}
          role={role}
          title={branchStockReportConst.title}
          col1={isManager ? 3 : 5}
          col2={isManager ? 0 : 0}
          col3={isManager ? 7 : 10}
        />
        <tbody>
          <tr>
            {header?.map((item, key) => (
              <th
                key={key}
                style={{
                  border: '1px solid black',
                  padding: '15px',
                }}
              >
                {item}
              </th>
            ))}
          </tr>
          {stockByBranch?.map((row, key) => (
            <tr key={key}>
              <td
                style={{
                  border: '1px solid black',
                  textAlign: 'center',
                  padding: '15px',
                }}
              >
                {row.sku}
              </td>
              <td
                style={{
                  border: '1px solid black',
                  textAlign: 'center',
                  padding: '15px',
                }}
              >
                {row.category}
              </td>
              <td
                style={{
                  border: '1px solid black',
                  textAlign: 'center',
                  padding: '5px',
                }}
              >
                {row.product}
              </td>
              <td
                style={{
                  border: '1px solid black',
                  textAlign: 'center',
                  padding: '5px',
                }}
              >
                {row.quantity}
              </td>
              <td
                style={{
                  border: '1px solid black',
                  textAlign: 'center',
                  padding: '5px',
                }}
              >
                {row.allotment}
              </td>
              <td
                style={{
                  border: '1px solid black',
                  textAlign: 'center',
                  padding: '5px',
                }}
              >
                {reportDate(row.expiresIn)}
              </td>
              {row.unitPrice && (
                <td
                  style={{
                    border: '1px solid black',
                    textAlign: 'center',
                    padding: '5px',
                  }}
                >
                  {formatPrice(row.unitPrice)}
                </td>
              )}
              {row.unitPrice && row.quantity && (
                <td
                  style={{
                    border: '1px solid black',
                    textAlign: 'center',
                    padding: '5px',
                  }}
                >
                  {formatPrice(Number(row.unitPrice) * Number(row.quantity))}
                </td>
              )}
              <td
                style={{
                  border: '1px solid black',
                  textAlign: 'center',
                  padding: '5px',
                }}
              ></td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td
              colSpan={3}
              style={{
                border: '1px solid black',
                textAlign: 'center',
                backgroundColor: '#e6e9eb',
                padding: '15px',
              }}
            >
              <h4>CANTIDAD DE PRODUCTO EN STOCK</h4>
            </td>
            <td
              style={{
                border: '1px solid black',
                textAlign: 'center',
                backgroundColor: '#e6e9eb',
                padding: '15px',
              }}
            >
              <h5>{totalStock}</h5>
            </td>
            <td
              colSpan={3}
              style={{
                border: '1px solid black',
                textAlign: 'center',
                backgroundColor: '#e6e9eb',
                padding: '15px',
              }}
            >
              {!isManager && <h4>MONTO TOTAL</h4>}
            </td>
            {!isManager && (
              <td
                colSpan={2}
                style={{
                  border: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#e6e9eb',
                  padding: '15px',
                }}
              >
                <h5>{formatPrice(totalAmount)}</h5>
              </td>
            )}
          </tr>
        </tfoot>
      </table>
    </div>
  );
};
