import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Row } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProducts } from '../../../../appRedux/actions/Product';
import {
  fullNavigationRoles,
  messages,
  oneColumnFormConfig,
  placeHolders,
  productStockColumnsFormConfig,
  stockColumnFormConfig,
  twoColumnsFormConfig,
} from '../../../../constants';
import { CustomSelect, NumericInput } from '../../../uiComponents';
export const AssortmentOrderProductsCreate = ({
  privileges = {},
  form = [],
  isCreateMode = false,
  isApproveMode = false,
  isConfirmMode = false,
  maxMin = [],
  stockByBranch = [],
  rows = 0,
}) => {
  const formProducts = form.getFieldValue('assortmentOrderProducts');
  const { role } = useSelector((state) => state.userAuth);
  const [selectedItems, setSelectedItems] = useState(
    formProducts?.map((item) => item?.product)
  );
  const [currentProduct, setCurrentProduct] = useState();

  const filteredOptions = form.getFieldValue('branchId')
    ? stockByBranch.filter(
        (product) =>
          !selectedItems?.map((item) => item?.value).includes(product.productId)
      )
    : [];

  const itemConfig = fullNavigationRoles.includes(role)
    ? productStockColumnsFormConfig
    : oneColumnFormConfig;

  const widthContiditon = (fields) => {
    if (fullNavigationRoles.includes(role)) return 19;
    else if (!fullNavigationRoles.includes(role) && fields.length > 1)
      return 23;
    else return 24;
  };

  const setMaxInput = useCallback(() => {
    const formItems = form.getFieldValue('assortmentOrderProducts');
    const selectedProducts = formItems?.map((item) => item?.productId);
    const index = selectedProducts.findIndex(
      (item) => item?.key === currentProduct?.value
    );

    const product = maxMin.find(
      ({ product }) => product && product.id === currentProduct?.value
    );

    formItems[index].max = product?.max ?? 0;
    form.setFieldsValue({
      assortmentOrderProducts: formItems,
    });
  }, [currentProduct, form, maxMin]);

  useEffect(() => {
    if (currentProduct) {
      setMaxInput();
    }
  }, [setMaxInput, currentProduct]);

  const onProductChange = (product = null, productData) => {
    setSelectedItems(
      form
        .getFieldValue('assortmentOrderProducts')
        ?.map((item) => item?.productId)
    );

    setCurrentProduct(productData);

    const formItems = form.getFieldValue('assortmentOrderProducts');

    const selectedProducts = formItems?.map((item) => item?.productId);
    const index = selectedProducts.findIndex(
      (item) => item?.key === product?.key
    );

    if (formItems[index] && index >= 0 && product) {
      formItems[index].stock =
        productData?.stock && +productData?.stock > 0 ? productData?.stock : 0;
      form.setFieldsValue({
        products: formItems,
      });
    }
  };

  return (
    <>
      <Form.List name="assortmentOrderProducts">
        {(fields, { add, remove }) => (
          <div className="products-container">
            {fields.map((field) => (
              <>
                <hr className="divider" />
                <Row wrap>
                  <Col span={widthContiditon(fields)}>
                    {stockByBranch && (
                      <Form.Item
                        {...itemConfig}
                        label="Producto"
                        name={[field.name, 'productId']}
                        rules={[
                          {
                            required: true,
                            message: messages.required('Producto'),
                          },
                        ]}
                      >
                        <CustomSelect
                          placeholder={placeHolders.product}
                          onChange={onProductChange}
                          labelInValue={true}
                          disabled={field?.key < rows}
                          options={filteredOptions.map((product) => ({
                            value: product?.productId,
                            label: `${product?.sku} - ${product?.name}`,
                            stock: product?.stock,
                          }))}
                        ></CustomSelect>
                      </Form.Item>
                    )}
                  </Col>
                  {fullNavigationRoles.includes(role) && (
                    <Col span={fields.length > 1 ? 4 : 5}>
                      <Form.Item
                        {...stockColumnFormConfig}
                        label="Stock"
                        initialValue={0}
                        name={[field.name, 'stock']}
                      >
                        <NumericInput disabled={true} min={1} />
                      </Form.Item>
                    </Col>
                  )}
                  <Col span={1}>
                    {fields.length > 1 && !isConfirmMode && (
                      <MinusCircleOutlined
                        className="danger-button-product"
                        onClick={() => {
                          remove(field.name);
                          onProductChange();
                        }}
                      />
                    )}
                  </Col>
                </Row>
                <Row wrap>
                  <Col span={12}>
                    {
                      <Form.Item
                        {...twoColumnsFormConfig}
                        label="Máximo"
                        name={[field.name, 'max']}
                        initialValue={0}
                      >
                        <NumericInput disabled={true} />
                      </Form.Item>
                    }
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      {...twoColumnsFormConfig}
                      label="Cantidad ordenada"
                      name={[field.name, 'orderedQuantity']}
                      dependencies={[field.name, 'max']}
                      initialValue={privileges.includes('approve') ? 0 : null}
                      rules={[
                        {
                          required: true,
                          message: messages.required('Cantidad ordenada'),
                        },
                        { type: 'orderedQuantity', warningOnly: true },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!isCreateMode) return Promise.resolve();

                            if (
                              Number(value) <=
                                getFieldValue('assortmentOrderProducts')[
                                  field.name
                                ]?.max ||
                              getFieldValue('assortmentOrderProducts')[
                                field.name
                              ]?.max === 0
                            ) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error(
                                'La cantidad excede el límite permitido'
                              )
                            );
                          },
                        }),
                      ]}
                    >
                      <NumericInput min={0} disabled={!isCreateMode} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row wrap>
                  <Col span={12}>
                    <Form.Item
                      {...twoColumnsFormConfig}
                      label="Cantidad a enviar"
                      name={[field.name, 'stockedQuantity']}
                      dependencies={[field.name, 'stock']}
                      rules={[
                        {
                          required: isApproveMode ? true : false,
                          message: messages.required('Cantidad enviada'),
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!isApproveMode) return Promise.resolve();

                            if (
                              value <=
                              getFieldValue('assortmentOrderProducts')[
                                field.name
                              ]?.stock
                            ) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error('Inventarío insuficiente')
                            );
                          },
                        }),
                      ]}
                    >
                      <NumericInput min={0} disabled={!isApproveMode} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      {...twoColumnsFormConfig}
                      label="Cantidad recibida"
                      name={[field.name, 'receivedQuantity']}
                      dependencies={[field.name, 'stock']}
                      rules={[
                        {
                          required: isConfirmMode ? true : false,
                          message: messages.required('Cantidad recibida'),
                        },
                      ]}
                    >
                      <NumericInput min={0} disabled={!isConfirmMode} />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            ))}
            {!isConfirmMode && (
              <Button
                className="add-btn"
                onClick={() => {
                  add();
                  onProductChange();
                }}
                icon={<PlusOutlined />}
              >
                Agregar producto
              </Button>
            )}
          </div>
        )}
      </Form.List>
    </>
  );
};
