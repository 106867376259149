import { Avatar, Card, Col, Row, Space, Tag } from 'antd';
import { stringDate } from '../../../../util/formatTime';
import { RenderStatus } from '../../../uiComponents';
import DefaultImage from '../../../../assets/images/ICONO-AZUL-100x100.png';

export const PackageModalBody = ({ data }) => {
  return (
    <>
      <Card
        bordered={false}
        cover={
          <Avatar
            className="avatar"
            alt="..."
            src={
              data?.photo
                ? `${process.env.REACT_APP_API}/v1/photo/thumbnail/${data?.photo?.name}`
                : DefaultImage
            }
          />
        }
        style={{ marginBottom: '0px' }}
      ></Card>
      <Row className="row">
        <Col span={8}>
          <h4>SKU</h4>
        </Col>
        <Col span={16}>{data?.sku || '-'}</Col>
      </Row>

      <Row className="row">
        <Col span={8}>
          <h4>Nombre</h4>
        </Col>
        <Col span={16}>{data?.name || '-'}</Col>
      </Row>

      <Row className="row">
        <Col span={8}>
          <h4>Tipo de tratamiento</h4>
        </Col>
        <Col span={16}>{data?.treatmentType?.name || '-'}</Col>
      </Row>

      <Row className="row">
        <Col span={8}>
          <h4>Categoría</h4>
        </Col>
        <Col span={16}>{data?.category?.name || '-'}</Col>
      </Row>

      <Row className="row">
        <Col span={8}>
          <h4>Descripción</h4>
        </Col>
        <Col span={16}>{data?.description}</Col>
      </Row>

      <Row className="row">
        <Col span={8}>
          <h4>Precio</h4>
        </Col>
        <Col span={16}>$ {data?.price}</Col>
      </Row>

      <Row className="row">
        <Col span={8}>
          <h4>Puntos</h4>
        </Col>
        <Col span={16}>{data?.points}</Col>
      </Row>

      <Row className="row">
        <Col span={8}>
          <h4>Tiempo en cabina</h4>
        </Col>
        <Col span={16}>{data?.cabainTime || '-'}</Col>
      </Row>

      <Row className="row">
        <Col span={8}>
          <h4>Valido en</h4>
        </Col>
        <Col span={16}>
          <Space wrap>
            {data.branches?.map((branch) => (
              <Tag key={`branch-${branch.name}`}>{`${branch.name}`}</Tag>
            ))}
          </Space>
        </Col>
      </Row>

      <Row className="row">
        <Col span={8}>
          <h4>Tipo de pago</h4>
        </Col>
        <Col span={16}>
          <Space wrap>
            {data.paymentType?.map((paymentType) => (
              <Tag key={`paymentType-${paymentType}`}>{`${paymentType}`}</Tag>
            ))}
          </Space>
        </Col>
      </Row>

      {data?.validBySchedule && (
        <>
          <Row className="row">
            <Col span={8}>
              <h4>Fecha de inicio</h4>
            </Col>
            <Col span={16}>{stringDate(data?.startDate) || '-'}</Col>
          </Row>

          <Row className="row">
            <Col span={8}>
              <h4>Fecha de finalización</h4>
            </Col>
            <Col span={16}>{stringDate(data?.endDataDate) || '-'}</Col>
          </Row>
        </>
      )}

      {data?.validBySchedule && data?.validByTime && (
        <>
          <Row className="row">
            <Col span={8}>
              <h4>Hora de inicio</h4>
            </Col>
            <Col span={16}>{`${data?.startTime} hrs.` || '-'}</Col>
          </Row>

          <Row className="row">
            <Col span={8}>
              <h4>Hora de finalización</h4>
            </Col>
            <Col span={16}>{`${data?.endTime} hrs.` || '-'}</Col>
          </Row>

          <Row className="row">
            <Col span={8}>
              <h4>Status</h4>
            </Col>
            <Col span={16}>
              <RenderStatus data={data} />
            </Col>
          </Row>
        </>
      )}
      <Row className="row">
        <Col span={24} style={{ textAlign: 'center' }}>
          <h4>Tratamientos</h4>
        </Col>
      </Row>
      <div style={{ textAlign: 'center' }}>
        <Row style={{ marginBottom: '10px' }}>
          <Col span={8}>
            <h4>Tratamiento</h4>
          </Col>
          <Col span={4}>
            <h4>Sesiones</h4>
          </Col>
          <Col span={4}>
            <h4>Límite</h4>
          </Col>
          <Col span={8}>
            <h4>Areas</h4>
          </Col>
        </Row>
        {data.treatmentPackage?.map((item) => (
          <Row key={item?.treatment?.id}>
            <Col span={8}>{item?.treatment?.name}</Col>
            <Col span={4}>{item?.sessions}</Col>
            <Col span={4}>{item?.limit}</Col>
            <Col span={8}>
              <Space wrap>
                {item?.areas?.map((area) => (
                  <Tag key={`area-${area.name}`}>{`${area.name}`}</Tag>
                ))}
              </Space>
            </Col>
          </Row>
        ))}
      </div>
    </>
  );
};
