import { useEffect, useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Form, Input } from 'antd';
import {
  approveAssortmentOrder,
  createAssortmentOrder,
  confirmAssortmentOrder,
  getAssortmentOrderById,
  rejectAssortmentOrder,
  getSubsidiaries,
  getMaxMinByBranch,
  getStockByBranchIdAndOrderType,
} from '../../../../appRedux/actions';
import {
  buttonLabel,
  cardConfig,
  formConfig,
  messages,
  placeHolders,
  orderTypes,
  roleConst,
} from '../../../../constants';
import {
  CancelButton,
  CustomSelect,
  NumericInput,
} from '../../../uiComponents';
import { AssortmentOrderProducts } from '../AssortmentOrderProducts';
import { AssortmentOrderProductsCreate } from '../AssortmentOrderProductsCreate';

export const AssortmentOrderForm = ({
  loggedUserName,
  privileges,
  mainBranchId,
  role,
}) => {
  const { id } = useParams();
  const { assortmentOrderById } = useSelector((state) => state.assortmentOrder);
  const { subsidiaries } = useSelector((state) => state.subsidiary);
  const { maxMinByBranch } = useSelector((state) => state.stock);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [response, setResponse] = useState();
  const { orderBy } = assortmentOrderById;
  const [orderByName, setOrderByName] = useState();
  const [action, setAction] = useState('create');
  const [branchId, setBranchId] = useState(mainBranchId);
  const [maxMin, setMaxMin] = useState([]);
  const [orderType, setOrderType] = useState();
  const [formDisabled, setFormDisabled] = useState(false);
  const { stockByBranch } = useSelector((state) => state.stock);
  const [rows, setRows] = useState(0);
  const [deletedAt, setDeletedAt] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const managers = useMemo(
    () => [roleConst.branchManager, roleConst.opertationalManager],
    []
  );

  const setOrderAction = useCallback(() => {
    if (id && !assortmentOrderById?.approvedAt && !managers.includes(role)) {
      setAction('approve');
    } else if (id && assortmentOrderById?.approvedAt) {
      setAction('confirm');
    }
  }, [assortmentOrderById, id, managers, role]);

  useEffect(() => {
    if (id && orderType) {
      dispatch(getStockByBranchIdAndOrderType(orderType, action));
    } else if (orderType) {
      dispatch(getStockByBranchIdAndOrderType(orderType, action, null, true));
    }
  }, [dispatch, id, orderType, action]);

  useEffect(() => {
    if (managers.includes(role)) {
      form.setFieldsValue({ branchId: mainBranchId });
    }
  }, [form, privileges, mainBranchId, managers, role]);

  useEffect(() => {
    setOrderAction();
  }, [setOrderAction]);

  useEffect(() => {
    if (id) {
      dispatch(getAssortmentOrderById(id));
    }
    dispatch(getSubsidiaries({ status: '1' }));
  }, [dispatch, id]);

  useEffect(() => {
    if (id && assortmentOrderById) {
      setOrderType(assortmentOrderById.orderType);
    }
  }, [id, assortmentOrderById, form]);

  useEffect(() => {
    if (response && [200, 201].includes(response))
      navigate('/dashboard/ordenes-de-surtido');
  }, [navigate, response]);

  useEffect(() => {
    if (assortmentOrderById && id) {
      assortmentOrderById.branchId = assortmentOrderById?.branch?.id;
      form.setFieldsValue(assortmentOrderById);
      assortmentOrderById?.assortmentOrderProduct?.forEach((item) => {
        let stockProduct = null;
        if (item?.productStock?.id) {
          stockProduct = stockByBranch?.find(
            (stock) => item?.productStock?.id === stock.stockId
          );
        } else {
          stockProduct = stockByBranch?.find(
            (stock) => stock.productId === item.product.id
          );
        }

        item.productId = item?.product?.id;
        item.stock =
          stockProduct?.stock && +stockProduct?.stock > 0
            ? stockProduct?.stock
            : 0;
        item.productStockField =
          item?.productStock?.id ?? stockProduct?.stockId;
      });
      setBranchId(assortmentOrderById?.branch?.id);
      const products = assortmentOrderById?.assortmentOrderProduct?.map(
        (item) => ({
          ...item,
          max: item?.product?.maxMin[0]?.max || 0,
        })
      );

      setRows(products?.length);
      setDeletedAt(assortmentOrderById?.deleted_at);

      form.setFieldsValue({
        assortmentOrderProducts: products,
      });
    }
  }, [form, id, assortmentOrderById, stockByBranch]);

  useEffect(() => {
    if (orderBy) {
      setOrderByName(`${orderBy.name} ${orderBy.lastName}`);
    }
  }, [orderBy]);

  const maxMinCall = useCallback(async () => {
    if (branchId) {
      dispatch(await getMaxMinByBranch(branchId));
    }
  }, [dispatch, branchId]);

  useEffect(() => {
    maxMinCall();
  }, [maxMinCall, branchId]);

  useEffect(() => {
    if (maxMinByBranch) {
      setMaxMin(maxMinByBranch);
    }
  }, [maxMinByBranch]);

  useEffect(() => {
    if (id && assortmentOrderById) {
      const status =
        assortmentOrderById.finishedAt ||
        assortmentOrderById.rejectedAt ||
        assortmentOrderById.deleted_at
          ? true
          : false;
      setFormDisabled(status);
    }
  }, [assortmentOrderById, id]);

  const onReject = async () => {
    setResponse(await dispatch(rejectAssortmentOrder(id)));
  };

  const onOrderTypeChange = (value) => {
    setOrderType(value);
    form.setFieldsValue({ products: [''] });
  };

  const onSubmit = async (values) => {
    setIsLoading(true);
    if (!id) {
      values.assortmentOrderProducts = values?.assortmentOrderProducts?.map(
        (item) => ({
          ...item,
          productId: item.productId.value ?? item.productId,
        })
      );
    } else {
      values.assortmentOrderProducts = values?.assortmentOrderProducts?.map(
        (item) => ({
          ...item,
          stockId: item.productStockField.value ?? item.productStockField,
        })
      );
    }

    if (managers.includes(role)) {
      values.branchId = branchId;
    }

    if (action === 'create') {
      setResponse(await dispatch(createAssortmentOrder(values)));
    } else if (action === 'approve') {
      setResponse(await dispatch(approveAssortmentOrder(id, values)));
    } else if (action === 'confirm') {
      setResponse(await dispatch(confirmAssortmentOrder(id, values)));
    }
    setIsLoading(false);
  };

  return (
    <>
      <Card size="small" bordered={true} style={cardConfig}>
        <Form
          {...formConfig}
          id="onSubmit"
          initialValues={{ assortmentOrderProducts: [''] }}
          autoComplete="off"
          onFinish={onSubmit}
          form={form}
          disabled={formDisabled}
        >
          {id && (
            <Form.Item
              {...formConfig}
              name="orderNumber"
              label="Número de orden"
            >
              <NumericInput disabled={true} />
            </Form.Item>
          )}

          <Form.Item
            {...formConfig}
            name="orderType"
            label="Tipo de orden"
            rules={[
              { required: true, message: messages.required('Tipo de ordén') },
            ]}
          >
            <CustomSelect
              placeholder="Tipo de orden"
              disabled={id && true}
              onChange={(value) => onOrderTypeChange(value)}
              options={orderTypes.map(({ value, label }) => ({
                value,
                label,
              }))}
            ></CustomSelect>
          </Form.Item>

          {!managers.includes(role) && (
            <Form.Item
              {...formConfig}
              name="branchId"
              label="Sucursal"
              rules={[
                {
                  required: true,
                  message: messages.required('Sucursal'),
                },
              ]}
            >
              <CustomSelect
                placeholder={placeHolders.branch}
                disabled={id && true}
                onChange={setBranchId}
                options={subsidiaries.map(({ id, name }) => ({
                  label: name,
                  value: id,
                }))}
              ></CustomSelect>
            </Form.Item>
          )}

          {id && (
            <Form.Item {...formConfig} label="Ordenó">
              <Input disabled={true} value={orderByName} />
            </Form.Item>
          )}

          {id && (
            <Form.Item {...formConfig} label="Aprobó">
              <Input disabled={true} defaultValue={loggedUserName} />
            </Form.Item>
          )}

          {!id ? (
            <AssortmentOrderProductsCreate
              privileges={privileges}
              isCreateMode={action === 'create'}
              form={form}
              deletedAt={deletedAt}
              isApproveMode={action === 'approve'}
              isConfirmMode={action === 'confirm'}
              maxMin={maxMin}
              stockByBranch={stockByBranch}
              rows={rows}
            />
          ) : (
            <AssortmentOrderProducts
              privileges={privileges}
              isCreateMode={action === 'create'}
              form={form}
              deletedAt={deletedAt}
              isApproveMode={action === 'approve'}
              isConfirmMode={action === 'confirm'}
              maxMin={maxMin}
              stockByBranch={stockByBranch}
              rows={rows}
            />
          )}

          <Button
            type="primary"
            htmlType="submit"
            className="btn-form"
            form="onSubmit"
            loading={isLoading}
          >
            {action === 'approve' ? buttonLabel.approve : buttonLabel.save}
          </Button>

          {!role.includes(role) && action === 'approve' && (
            <Button type="danger" className="btn-form" onClick={onReject}>
              {buttonLabel.reject}
            </Button>
          )}
        </Form>
        <CancelButton path="/dashboard/ordenes-de-surtido" />
      </Card>
    </>
  );
};
