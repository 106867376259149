import { combineReducers } from 'redux';
import AlertManager from './AlertManager';
import Settings from './Settings';
import Common from './Common';
import UserAuth from './UserAuth';
import User from './User';
import Subsidiary from './Subsidiary';
import Category from './Category';
import Appliances from './Appliances';
import Product from './Product';
import Cabain from './Cabain';
import CabainType from './CabainType';
import Ui from './Ui';
import SaleOrder from './SaleOrder';
import ProductTypes from './ProductTypes';
import Treatment from './Treatment';
import TreatmentType from './TreatmentType';
import Area from './Area';
import PurchaseOrder from './PurchaseOrder';
import Provider from './Provider';
import AssortmentOrder from './AssortmentOrder';
import Color from './Color';
import Stock from './Stock';
import Role from './Role';
import JobPosition from './JobPosition';
import Department from './Department';
import Log from './Log';
import Transfer from './Transfer';
import Customer from './Customer';
import Consent from './Consent';
import Package from './Package';
import Lock from './Lock';
import Agenda from './Agenda';
import Note from './Note';
import Module from './Module';
import Diary from './Diary';
import GeneralStates from './General';
import Configs from './Configs';
import Loading from './Loading';
import BranchAppliances from './BranchAppliances';
import Sales from './Sales';
import CashSales from './CashSales';
import Commission from './Commissions';
import DailyCashClosingCash from './DailyCashClosingCash';
import DailyCashClosing from './DailyCashClosing';
import MissedAppointment from './MissedAppointment';
import Coupon from './Coupon';
import Reports from './Reports';
import PurchasedProduct from './PurchasedProduct';

const createRootReducer = combineReducers({
  agenda: Agenda,
  alertManager: AlertManager,
  appliances: Appliances,
  area: Area,
  assortmentOrder: AssortmentOrder,
  branchAppliance: BranchAppliances,
  cabain: Cabain,
  cabainType: CabainType,
  category: Category,
  cashSales: CashSales,
  color: Color,
  configs: Configs,
  consent: Consent,
  commission: Commission,
  common: Common,
  coupon: Coupon,
  customer: Customer,
  dailyCashClosing: DailyCashClosing,
  dailyCashClosingCash: DailyCashClosingCash,
  departments: Department,
  diary: Diary,
  generalStates: GeneralStates,
  jobPositions: JobPosition,
  loading: Loading,
  lock: Lock,
  logs: Log,
  missedAppointment: MissedAppointment,
  modules: Module,
  notes: Note,
  packages: Package,
  product: Product,
  productTypes: ProductTypes,
  provider: Provider,
  purchaseOrder: PurchaseOrder,
  purchasedProduct: PurchasedProduct,
  sales: Sales,
  saleOrder: SaleOrder,
  settings: Settings,
  subsidiary: Subsidiary,
  stock: Stock,
  reports: Reports,
  role: Role,
  transfer: Transfer,
  treatment: Treatment,
  treatmentType: TreatmentType,
  ui: Ui,
  user: User,
  userAuth: UserAuth,
});

export default createRootReducer;
