import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { Categories } from '../pages/Categories/Categories';
import { Category } from '../pages/Categories/Category';
import { Login } from '../pages/Login/Login';
import { QuickLogin } from '../pages/QuickLogin/QuickLogin';
import { User, Users } from '../pages/Users';
import { Dashboard } from '../layout/Dashboard';
import { Subsidiary, Subsidiaries } from '../pages/Subsidiary';
import { Cabain } from '../pages/Cabain/Cabain';
import { Cabains } from '../pages/Cabain/Cabains';
import { CabainType } from '../pages/CabainType/CabainType';
import { CabainTypes } from '../pages/CabainType/CabainTypes';
import { Product, Products } from '../pages/Products';
import { ProductType } from '../pages/ProductType';
import { Reorder } from '../pages/ProductOrder/Reorder';
import { ReorderList } from '../pages/ProductOrder/ReorderLIst';
import { SaleOrder, SaleOrders } from '../pages/SaleOrder';
import { Treatment, Treatments } from '../pages/Treatments';
import { PurchaseOrder, PurchaseOrders } from '../pages/PurchaseOrder';
import { Provider, Providers } from '../pages/Providers';
import { AssortmentOrder, AssortmentOrders } from '../pages/AssortmentOrder';
import { Stock } from '../pages/Stock/stock';
import { History } from '../pages/History';
import { Transfer, Transfers } from '../pages/Transfer';
import { Customer, Customers } from '../pages/Customer';
import { Agenda, Appointment, Appointments } from '../pages/Agenda';
import { Package, Packages } from '../pages/Package';
import { Promotion, Promotions } from '../pages/Promotion';
import { Lock } from '../pages/Lock';
import { ForgotPassword } from '../pages/ForgotPassword/ForgotPassword';
import { Diary } from '../pages/Diary';
import { Settings, Setting } from '../pages/Settings';
import { Appliance, Appliances } from '../pages/Appliances';
import { BranchAppliance, BranchAppliances } from '../pages/BranchAppliances';
import { AppSales, BranchSales, CashSales, OnlineSales } from '../pages/Sales';
import { DailyCashClosing } from '../pages/DailyCashClosing/DailyCashClosing';
import { DailyCashClosingCash } from '../pages/DailyCashClosing/DailyCashClosingCash';
import { Loyalty } from '../pages/Loyalty';
import { Members } from '../pages/Members';
import { WeMissYou } from '../pages/WeMissYou/WeMissYou';
import { CosmetologyCommission } from '../pages/CosmetologyCommissions';
import { MedicalCommission } from '../pages/MedicalCommissions';
import { Coupon, Coupons } from '../pages/Coupons';
import { IddleComponent } from '../components/IddleComponent';
import { Reports } from '../pages/Reports/Reports';

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/quick-login" element={<QuickLogin />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/forgot-password/:code" element={<ForgotPassword />} />

        <Route path="/dashboard" element={<Dashboard />}>
          {/* Personal  */}
          <Route path="/dashboard/personal/:id" element={<User />} />
          <Route path="/dashboard/personal/crear" element={<User />} />
          <Route path="/dashboard/personal" element={<Users />} />

          {/* Categorias */}
          <Route path="/dashboard/categorias/:id" element={<Category />} />
          <Route path="/dashboard/categorias/crear" element={<Category />} />
          <Route path="/dashboard/categorias" element={<Categories />} />

          {/* Aparatos */}
          <Route path="/dashboard/aparatos/:id" element={<Appliance />} />
          <Route path="/dashboard/aparatos/crear" element={<Appliance />} />
          <Route path="/dashboard/aparatos" element={<Appliances />} />

          {/* Branch Aparatos */}
          <Route
            path="/dashboard/aparatos-sucursal/:id"
            element={<BranchAppliance />}
          />
          <Route
            path="/dashboard/aparatos-sucursal/vincular"
            element={<BranchAppliance />}
          />
          <Route
            path="/dashboard/aparatos-sucursal"
            element={<BranchAppliances />}
          />

          {/* Sucursales */}
          <Route path="/dashboard/sucursales/:id" element={<Subsidiary />} />
          <Route path="/dashboard/sucursales/crear" element={<Subsidiary />} />
          <Route path="/dashboard/sucursales" element={<Subsidiaries />} />

          {/* Productos  */}
          <Route path="/dashboard/productos/:id" element={<Product />} />
          <Route path="/dashboard/productos/crear" element={<Product />} />
          <Route path="/dashboard/productos" element={<Products />} />

          {/* Tipo de Productos  */}
          <Route path="/dashboard/tipos-producto" element={<ProductType />} />

          {/* Cabinas */}
          <Route path="/dashboard/cabinas/:id" element={<Cabain />} />
          <Route path="/dashboard/cabinas/crear" element={<Cabain />} />
          <Route path="/dashboard/cabinas" element={<Cabains />} />

          {/* Tipo de Cabinas */}
          <Route path="/dashboard/tipos-cabina/:id" element={<CabainType />} />
          <Route
            path="/dashboard/tipos-cabina/crear"
            element={<CabainType />}
          />
          <Route path="/dashboard/tipos-cabina" element={<CabainTypes />} />

          {/* Product Reorder */}
          <Route path="/dashboard/resurtidos/:id" element={<Reorder />} />
          <Route path="/dashboard/resurtidos/crear" element={<Reorder />} />
          <Route path="/dashboard/resurtidos" element={<ReorderList />} />

          {/* Sale Order  */}
          <Route
            path="/dashboard/ordenes-de-venta/:id"
            element={<SaleOrder />}
          />
          <Route
            path="/dashboard/ordenes-de-venta/crear"
            element={<SaleOrder />}
          />
          <Route path="/dashboard/ordenes-de-venta" element={<SaleOrders />} />

          {/* Treatment */}
          <Route path="/dashboard/tratamientos/:id" element={<Treatment />} />
          <Route path="/dashboard/tratamientos/crear" element={<Treatment />} />
          <Route path="/dashboard/tratamientos" element={<Treatments />} />

          {/* Purchase Order */}
          <Route
            path="/dashboard/ordenes-de-compra/:id"
            element={<PurchaseOrder />}
          />
          <Route
            path="/dashboard/ordenes-de-compra/crear"
            element={<PurchaseOrder />}
          />
          <Route
            path="/dashboard/ordenes-de-compra"
            element={<PurchaseOrders />}
          />

          {/* Providers  */}
          <Route path="/dashboard/proveedores/:id" element={<Provider />} />
          <Route path="/dashboard/proveedores/crear" element={<Provider />} />
          <Route path="/dashboard/proveedores" element={<Providers />} />

          {/* Assortment Order  */}
          <Route
            path="/dashboard/ordenes-de-surtido/:id"
            element={<AssortmentOrder />}
          />
          <Route
            path="/dashboard/ordenes-de-surtido/crear"
            element={<AssortmentOrder />}
          />
          <Route
            path="/dashboard/ordenes-de-surtido"
            element={<AssortmentOrders />}
          />

          {/* Transfers */}
          <Route path="/dashboard/transferencias/:id" element={<Transfer />} />
          <Route
            path="/dashboard/transferencias/crear"
            element={<Transfer />}
          />
          <Route path="/dashboard/transferencias" element={<Transfers />} />

          <Route path="/dashboard/inventario" element={<Stock />} />

          {/* History */}
          <Route path="/dashboard/historial" element={<History />} />

          {/* Dairy */}
          <Route path="/dashboard/bitacora" element={<Diary />} />

          {/* Customers  */}
          <Route path="/dashboard/clientes/:id" element={<Customer />} />
          <Route path="/dashboard/clientes/crear" element={<Customer />} />
          <Route path="/dashboard/clientes" element={<Customers />} />

          {/* Agenda */}
          <Route path="/dashboard/agenda" element={<Agenda />} />
          <Route path="/dashboard/agenda/:id" element={<Appointment />} />
          <Route path="/dashboard/agenda/crear/" element={<Appointment />} />

          {/* Appointments */}
          <Route path="/dashboard/citas" element={<Appointments />} />

          {/* Packages  */}
          <Route path="/dashboard/paquetes/:id" element={<Package />} />
          <Route path="/dashboard/paquetes/crear" element={<Package />} />
          <Route path="/dashboard/paquetes" element={<Packages />} />

          {/* dailyCashClosing  */}
          <Route
            path="/dashboard/corte-sucursal"
            element={<DailyCashClosing />}
          />
          <Route
            path="/dashboard/corte-de-productos"
            element={<DailyCashClosingCash />}
          />

          {/* Sales  */}
          <Route
            path="/dashboard/ventas-sucursal-producto"
            element={<CashSales />}
          />
          <Route path="/dashboard/ventas-en-linea" element={<OnlineSales />} />
          <Route path="/dashboard/ventas-en-app" element={<AppSales />} />
          <Route
            path="/dashboard/ventas-en-sucursal"
            element={<BranchSales />}
          />

          {/* Commission  */}
          <Route
            path="/dashboard/comisiones-cosmetologicas"
            element={<CosmetologyCommission />}
          />
          <Route
            path="/dashboard/comisiones-medicas"
            element={<MedicalCommission />}
          />

          {/* Promotions  */}
          <Route path="/dashboard/promociones/:id" element={<Promotion />} />
          <Route path="/dashboard/promociones/crear" element={<Promotion />} />
          <Route path="/dashboard/promociones" element={<Promotions />} />

          {/* WeMissYou */}
          <Route path="/dashboard/we-miss-you" element={<WeMissYou />} />

          {/* Members */}
          <Route path="/dashboard/miembros" element={<Members />} />
          {/* Locks */}
          <Route path="/dashboard/bloqueos/:id" element={<Lock />} />
          <Route path="/dashboard/bloqueos/crear" element={<Lock />} />
          {/* <Route path='/dashboard/bloqueos/:userId' element={} /> */}

          {/* Loyalty */}
          <Route path="/dashboard/sistema-de-lealtad" element={<Loyalty />} />

          {/* Settings */}
          <Route path="/dashboard/configuraciones" element={<Settings />} />
          <Route
            path="/dashboard/configuraciones/editar/"
            element={<Setting />}
          />

          {/* Coupons */}
          <Route path="/dashboard/cupones/:id" element={<Coupon />} />
          <Route path="/dashboard/cupones/crear" element={<Coupon />} />
          <Route path="/dashboard/cupones" element={<Coupons />} />
          <Route path="/dashboard/reportes" element={<Reports />} />
        </Route>

        <Route path="*" element={<Navigate to="/dashboard" />} />
      </Routes>
      <IddleComponent />
    </BrowserRouter>
  );
};

export default Router;
