import { appointmentStatus, customerConst } from '../../../../constants';
import { stringDate, stringTime } from '../../../../util';
import { Table, Tag } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getAppointmentByCustomer } from '../../../../appRedux/actions';
import { arraySort } from '../../../../util/arraySort';

export const CustomerAppointmentsHistory = ({ appointments }) => {
  const dispatch = useDispatch();
  const { appointmentByCustomer } = useSelector((state) => state.agenda);

  const appointmentSorter = arraySort(
    appointmentByCustomer?.items,
    customerConst.appointmentNumber
  );

  useEffect(() => {
    dispatch(getAppointmentByCustomer({ customerId: appointments }));
  }, [dispatch, appointments]);

  const columns = [
    {
      title: 'Cita',
      dataIndex: 'appointmentNumber',
      key: 'appointmentNumber',
    },
    {
      title: 'Fecha de cita',
      dataIndex: 'date',
      key: 'date',
      render: (date) => <Tag color="green"> {stringDate(date)} </Tag>,
    },
    {
      title: 'Hora de cita',
      dataIndex: 'startTime',
      key: 'startTime',
      render: (startTime) => <Tag color="blue"> {stringTime(startTime)} </Tag>,
    },
    {
      title: 'Sucursal',
      dataIndex: 'name',
      key: 'branch',
      render: (_, { branch }) => <>{branch?.name}</>,
    },
    {
      title: 'Atendió',
      dataIndex: 'attendedBy',
      key: 'attendedBy',
      render: (attendedBy) => (
        <>
          {attendedBy?.name ? attendedBy?.name : '-'} {attendedBy?.lastName}{' '}
          {attendedBy?.motherLastName}
        </>
      ),
      path: [
        'attendedBy.name',
        'attendedBy.lastName',
        'attendedBy.motherLastName',
      ],
    },

    {
      title: 'Tratamiento',
      dataIndex: 'treatment',
      key: 'treatment',
      render: (treatment) => treatment?.name || '-',
      path: 'treatment.name',
    },
    {
      title: 'Observaciones',
      dataIndex: 'observations',
      key: 'observations',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <Tag color={`#${appointmentStatus[status]?.color}`}>
          {appointmentStatus[status]?.value}
        </Tag>
      ),
      path: 'status',
      formatter: (status) => appointmentStatus[status]?.value,
    },
  ];
  return (
    <Table columns={columns} dataSource={appointmentSorter} pagination={5} />
  );
};
