import React, { useEffect, useRef, useState } from 'react';
import { useDownloadExcel } from 'react-export-table-to-excel';
import { useDispatch, useSelector } from 'react-redux';
import { getBranchExpenses } from '../../../../appRedux/actions/Reports';
import { formatPrice } from '../../../../util/formatPrice';
import { ReportTableHeader } from '../../../uiComponents/ReportTableHeader';
import { stringDate } from '../../../../util';
import moment from 'moment';
import { ExportButton } from '../../../uiComponents/ExportButton';
import { formatFileName } from '../../../../util/strings';
import { branchExpensesReportConst } from '../../../../constants';

export const ExpensesbyBranchReport = () => {
  const tableRef = useRef(null);
  const dispatch = useDispatch();
  const { expensesByBranch } = useSelector((state) => state.reports);
  const { fullname, role } = useSelector((state) => state.userAuth);
  const { filtersData } = useSelector((state) => state.generalStates);
  const [header, setHeader] = useState([]);
  const [title, setTitle] = useState('');
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: formatFileName(branchExpensesReportConst.title),
    sheet: branchExpensesReportConst.title,
  });

  let subtotalAssortment = 0;
  let totalAssortment = 0;
  let subtotalSent = 0;
  let totalSent = 0;
  let subtotalReceived = 0;
  let totalReceived = 0;
  let subtotalReturn = 0;
  let totalReturn = 0;
  let rowNumber = 0;

  useEffect(() => {
    if (filtersData) {
      dispatch(
        getBranchExpenses({
          branchId: filtersData.branchId,
          month: filtersData.month,
        })
      );
    }
  }, [dispatch, filtersData]);

  useEffect(() => {
    const header = branchExpensesReportConst.header;
    setHeader(header);
    if (expensesByBranch) {
      const reportTitle = `${
        branchExpensesReportConst.title
      } ${expensesByBranch?.branch?.name?.toUpperCase()}`;
      setTitle(reportTitle);
    }
  }, [expensesByBranch, role]);

  const getExpireDate = (expiresIn) => {
    return expiresIn ? moment(expiresIn).format('DD/MM/YY') : '-';
  };

  return (
    <div style={{ width: '100%' }}>
      <ExportButton onDownload={onDownload} />
      <table
        ref={tableRef}
        style={{
          overflowX: 'scroll',
          margin: '0 auto',
        }}
      >
        <ReportTableHeader
          user={fullname}
          role={role}
          title={title}
          col1={5}
          col2={0}
          col3={9}
        />
        <tbody>
          <tr>
            {header?.map((item, key) => (
              <th
                key={key}
                style={{ border: '1px solid black', padding: '15px' }}
              >
                {item}
              </th>
            ))}
          </tr>

          {expensesByBranch?.assortmentProducts?.map((row, key) => (
            <React.Fragment key={key}>
              <tr>
                <td
                  colSpan={9}
                  style={{
                    border: '1px solid black',
                    textAlign: 'center',
                    backgroundColor: '#289DF4',
                    color: '#FFFFFF',
                    padding: '15px',
                  }}
                >
                  ORDEN DE RESURTIDO #OR{row.orderNumber} FECHA DE ORDEN:
                  {stringDate(row.created_at)}
                </td>
              </tr>
              {row.assortmentOrderProduct.map((row, key) => {
                rowNumber++;
                const { productStock: stock } = row ?? {};
                subtotalAssortment = stock?.price * row.receivedQuantity;
                totalAssortment += subtotalAssortment;

                return (
                  <tr key={key}>
                    <td
                      style={{
                        border: '1px solid black',
                        textAlign: 'center',
                        padding: '15px',
                      }}
                    >
                      {rowNumber}
                    </td>
                    <td
                      style={{
                        border: '1px solid black',
                        textAlign: 'center',
                        padding: '15px',
                      }}
                    >
                      {getExpireDate(stock?.expiresIn)}
                    </td>
                    <td
                      style={{
                        border: '1px solid black',
                        textAlign: 'center',
                        padding: '15px',
                      }}
                    >
                      {stock?.allotment}
                    </td>
                    <td
                      style={{
                        border: '1px solid black',
                        textAlign: 'center',
                        padding: '15px',
                      }}
                    >
                      {row.product.sku}
                    </td>
                    <td
                      style={{
                        border: '1px solid black',
                        textAlign: 'center',
                        padding: '15px',
                      }}
                    >
                      {row.product.name}
                    </td>
                    <td
                      style={{
                        border: '1px solid black',
                        textAlign: 'center',
                        padding: '15px',
                      }}
                    >
                      {row.orderedQuantity}
                    </td>
                    <td
                      style={{
                        border: '1px solid black',
                        textAlign: 'center',
                        padding: '15px',
                      }}
                    >
                      {formatPrice(stock?.price)}
                    </td>
                    <td
                      style={{
                        border: '1px solid black',
                        textAlign: 'center',
                        padding: '15px',
                      }}
                    >
                      {formatPrice(subtotalAssortment)}
                    </td>
                    <td
                      style={{
                        border: '1px solid black',
                        textAlign: 'center',
                        padding: '15px',
                      }}
                    ></td>
                  </tr>
                );
              })}
            </React.Fragment>
          ))}
          {expensesByBranch?.transferSentProducts?.map((row, key) => (
            <React.Fragment key={key}>
              <tr>
                <td
                  colSpan={9}
                  style={{
                    border: '1px solid black',
                    textAlign: 'center',
                    backgroundColor: '#FC6A03',
                    color: '#FFFFFF',
                    padding: '15px',
                  }}
                >
                  ENVIO DE PRODUCTO A OTRA SUCURSAL #TR{row.transferNumber}{' '}
                  FECHA DE TRANSFERENCIA:
                  {stringDate(row.created_at)}
                </td>
              </tr>
              {row.transferProduct.map((row, key) => {
                rowNumber++;
                const { productStock: stock } = row ?? {};
                subtotalSent = stock?.price * row.receivedQuantity;
                totalSent += subtotalSent;

                return (
                  <tr key={key}>
                    <td
                      style={{
                        border: '1px solid black',
                        textAlign: 'center',
                        padding: '15px',
                      }}
                    >
                      {rowNumber}
                    </td>
                    <td
                      style={{
                        border: '1px solid black',
                        textAlign: 'center',
                        padding: '15px',
                      }}
                    >
                      {getExpireDate(stock?.expiresIn)}
                    </td>
                    <td
                      style={{
                        border: '1px solid black',
                        textAlign: 'center',
                        padding: '15px',
                      }}
                    >
                      {stock?.allotment}
                    </td>
                    <td
                      style={{
                        border: '1px solid black',
                        textAlign: 'center',
                        padding: '15px',
                      }}
                    >
                      {row.product.sku}
                    </td>
                    <td
                      style={{
                        border: '1px solid black',
                        textAlign: 'center',
                        padding: '15px',
                      }}
                    >
                      {row.product.name}
                    </td>
                    <td
                      style={{
                        border: '1px solid black',
                        textAlign: 'center',
                        padding: '15px',
                      }}
                    >
                      {row.receivedQuantity}
                    </td>
                    <td
                      style={{
                        border: '1px solid black',
                        textAlign: 'center',
                        padding: '15px',
                      }}
                    >
                      {formatPrice(stock?.price)}
                    </td>
                    <td
                      style={{
                        border: '1px solid black',
                        textAlign: 'center',
                        padding: '15px',
                      }}
                    >
                      {formatPrice(subtotalSent)}
                    </td>
                    <td
                      style={{
                        border: '1px solid black',
                        textAlign: 'center',
                        padding: '15px',
                      }}
                    ></td>
                  </tr>
                );
              })}
            </React.Fragment>
          ))}

          {expensesByBranch?.transferReceivedProducts?.map((row, key) => (
            <React.Fragment key={key}>
              <tr>
                <td
                  colSpan={9}
                  style={{
                    border: '1px solid black',
                    textAlign: 'center',
                    backgroundColor: '#4CAF50',
                    color: '#FFFFFF',
                    padding: '15px',
                  }}
                >
                  RECEPCION DE PRODUCTO DE OTRA SUCURSAL #TR
                  {row.transferNumber} FECHA DE TRANSFERENCIA:
                  {stringDate(row.created_at)}
                </td>
              </tr>
              {row.transferProduct.map((row, key) => {
                rowNumber++;
                const { stockProduct: stock } = row ?? {};
                subtotalReceived = stock?.price * row.receivedQuantity;
                totalReceived += subtotalReceived;

                return (
                  <tr key={key}>
                    <td
                      style={{
                        border: '1px solid black',
                        textAlign: 'center',
                        padding: '15px',
                      }}
                    >
                      {rowNumber}
                    </td>
                    <td
                      style={{
                        border: '1px solid black',
                        textAlign: 'center',
                        padding: '15px',
                      }}
                    >
                      {getExpireDate(stock?.expiresIn)}
                    </td>
                    <td
                      style={{
                        border: '1px solid black',
                        textAlign: 'center',
                        padding: '15px',
                      }}
                    >
                      {stock?.allotment}
                    </td>
                    <td
                      style={{
                        border: '1px solid black',
                        textAlign: 'center',
                        padding: '15px',
                      }}
                    >
                      {row.product.sku}
                    </td>
                    <td
                      style={{
                        border: '1px solid black',
                        textAlign: 'center',
                        padding: '15px',
                      }}
                    >
                      {row.product.name}
                    </td>
                    <td
                      style={{
                        border: '1px solid black',
                        textAlign: 'center',
                        padding: '15px',
                      }}
                    >
                      {row.receivedQuantity}
                    </td>
                    <td
                      style={{
                        border: '1px solid black',
                        textAlign: 'center',
                        padding: '15px',
                      }}
                    >
                      {formatPrice(stock?.price)}
                    </td>
                    <td
                      style={{
                        border: '1px solid black',
                        textAlign: 'center',
                        padding: '15px',
                      }}
                    >
                      {formatPrice(subtotalReceived)}
                    </td>
                    <td
                      style={{
                        border: '1px solid black',
                        textAlign: 'center',
                        padding: '15px',
                      }}
                    ></td>
                  </tr>
                );
              })}
            </React.Fragment>
          ))}

          {expensesByBranch?.devolutionProducts?.map((row, key) => (
            <React.Fragment key={key}>
              <tr>
                <td
                  colSpan={9}
                  style={{
                    border: '1px solid black',
                    textAlign: 'center',
                    backgroundColor: '#D0312D',
                    color: '#FFFFFF',
                    padding: '15px',
                  }}
                >
                  DEVOLUCION A CENTRO OPERATIVO #TR{row.transferNumber} FECHA DE
                  TRANSFERENCIA:
                  {stringDate(row.created_at)}
                </td>
              </tr>
              {row.transferProduct.map((row, key) => {
                rowNumber++;
                const { productStock: stock } = row ?? {};
                subtotalReturn = stock?.price * row.receivedQuantity;
                totalReturn += subtotalReturn;

                return (
                  <tr key={key}>
                    <td
                      style={{
                        border: '1px solid black',
                        textAlign: 'center',
                        padding: '15px',
                      }}
                    >
                      {rowNumber}
                    </td>
                    <td
                      style={{
                        border: '1px solid black',
                        textAlign: 'center',
                        padding: '15px',
                      }}
                    >
                      {getExpireDate(stock?.expiresIn)}
                    </td>
                    <td
                      style={{
                        border: '1px solid black',
                        textAlign: 'center',
                        padding: '15px',
                      }}
                    >
                      {stock?.allotment}
                    </td>
                    <td
                      style={{
                        border: '1px solid black',
                        textAlign: 'center',
                        padding: '15px',
                      }}
                    >
                      {row.product.sku}
                    </td>
                    <td
                      style={{
                        border: '1px solid black',
                        textAlign: 'center',
                        padding: '15px',
                      }}
                    >
                      {row.product.name}
                    </td>
                    <td
                      style={{
                        border: '1px solid black',
                        textAlign: 'center',
                        padding: '15px',
                      }}
                    >
                      {row.receivedQuantity}
                    </td>
                    <td
                      style={{
                        border: '1px solid black',
                        textAlign: 'center',
                        padding: '15px',
                      }}
                    >
                      {formatPrice(stock?.price)}
                    </td>
                    <td
                      style={{
                        border: '1px solid black',
                        textAlign: 'center',
                        padding: '15px',
                      }}
                    >
                      {formatPrice(subtotalReturn)}
                    </td>
                    <td
                      style={{
                        border: '1px solid black',
                        textAlign: 'center',
                        padding: '15px',
                      }}
                    ></td>
                  </tr>
                );
              })}
            </React.Fragment>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td
              colSpan={7}
              style={{
                border: '1px solid black',
                textAlign: 'right',
                backgroundColor: '#e6e9eb',
                padding: '15px',
              }}
            >
              <h4>+ ORDENES DE RESURTIDO</h4>
            </td>
            <td
              colSpan={2}
              style={{
                border: '1px solid black',
                textAlign: 'center',
                backgroundColor: '#e6e9eb',
                padding: '15px',
              }}
            >
              {formatPrice(totalAssortment)}
            </td>
          </tr>
          <tr>
            <td
              colSpan={7}
              style={{
                border: '1px solid black',
                textAlign: 'right',
                backgroundColor: '#e6e9eb',
                padding: '15px',
              }}
            >
              <h4>+ TRANSFERENCIAS RECIBIDAS</h4>
            </td>
            <td
              colSpan={2}
              style={{
                border: '1px solid black',
                textAlign: 'center',
                backgroundColor: '#e6e9eb',
                padding: '15px',
              }}
            >
              {formatPrice(totalReceived)}
            </td>
          </tr>
          <tr>
            <td
              colSpan={7}
              style={{
                border: '1px solid black',
                textAlign: 'right',
                backgroundColor: '#e6e9eb',
                padding: '15px',
              }}
            >
              <h4>- TRANSFERENCIAS ENVIADAS</h4>
            </td>
            <td
              colSpan={2}
              style={{
                border: '1px solid black',
                textAlign: 'center',
                backgroundColor: '#e6e9eb',
                padding: '15px',
              }}
            >
              {formatPrice(totalSent)}
            </td>
          </tr>
          <tr>
            <td
              colSpan={7}
              style={{
                border: '1px solid black',
                textAlign: 'right',
                backgroundColor: '#e6e9eb',
                padding: '15px',
              }}
            >
              <h4>- DEVOLUCIONES A CENTRO OPERATIVO</h4>
            </td>
            <td
              colSpan={2}
              style={{
                border: '1px solid black',
                textAlign: 'center',
                backgroundColor: '#e6e9eb',
                padding: '15px',
              }}
            >
              {formatPrice(totalReturn)}
            </td>
          </tr>
          <tr>
            <td
              colSpan={7}
              style={{
                border: '1px solid black',
                textAlign: 'right',
                backgroundColor: '#e6e9eb',
                padding: '15px',
              }}
            >
              <h4>GASTO TOTAL DEL PERIODO</h4>
            </td>
            <td
              colSpan={2}
              style={{
                border: '1px solid black',
                textAlign: 'center',
                backgroundColor: '#e6e9eb',
                padding: '15px',
              }}
            >
              {formatPrice(
                totalAssortment + totalReceived - totalSent - totalReturn
              )}
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};
