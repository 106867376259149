export const timeZones = {
  'UTC(CNM) – 5': 'Tiempo del Sureste',
  'UTC(CNM) – N': 'Tiempo del Centro',
  'UTC(CNM) – (N+1)': 'Tiempo del Pacífico',
  'UTC(CNM) – (N+2)': 'Tiempo del Noroeste',
};

export const timeFormat = 'HH:mm';
export const timeFormatComplete = 'HH:mm:ss';
export const dateFormat = 'YYYY-MM-DD';
export const timeFormat12 = 'hh:mm A';
export const timeFormat24 = 'hh:mm';
export const midnight = '00:00:00';
export const dateTime = 'YYYY-MM-DD hh:mm A';
export const fullDate = 'YYYY-MM-DD HH:mm:ss';
