import { Button, Modal } from 'antd';
import { useState } from 'react';
import { CheckinForm } from '../../app/components/Checkin';
import { buttonLabel } from '../../constants';

const AppsNavigation = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Button type='link' className="checkin-button" onClick={showModal}>
        {buttonLabel.checkin}
      </Button>
      <Modal
        title="Registrar Entrada"
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <CheckinForm setIsModalOpen={setIsModalOpen} />
      </Modal>
    </>
  );
};

export default AppsNavigation;
