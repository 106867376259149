// UI
export const COLLAPSED_SIDER = 'COLLAPSED_SIDER';
export const MODAL_VIEW = 'MODAL_VIEW';
export const DISABLED_ELEMENT = 'DISABLED_ELEMENT';

// ALERT MANAGER
export const SET_ERROR = 'SET_ERROR';
export const DELETE_ERROR = 'DELETE_ERROR';
export const SET_SUCCESS = 'SET_SUCCESS';
export const DELETE_SUCCESS = 'DELETE_SUCCESS';

//CABAIN TYPES
export const CREATE_CABAIN_TYPE = 'CREATE_CABAIN_TYPE';
export const DELETE_CABAIN_TYPE = 'DELETE_CABAIN_TYPE';
export const GET_CABAIN_TYPE_DELETE = 'GET_CABAIN_TYPE_DELETE';
export const GET_CABAIN_TYPES = 'GET_CABAIN_TYPES';
export const GET_CABAIN_TYPE_BY_ID = 'GET_CABAIN_TYPE_BY_ID';
export const UPDATE_CABAIN_TYPE = 'UPDATE_CABAIN_TYPE';

//COLORS
export const GET_COLORS = 'GET_COLORS';

// CRUD SUBSIDIARY
export const CREATE_SUBSIDIARY = 'CREATE_SUBSIDIARY';
export const GET_SUBSIDIARIES = 'GET_SUBSIDIARIES';
export const GET_SUBSIDIARY_BY_ID = 'GET_SUBSIDIARY_BY_ID';
export const UPDATE_SUBSIDIARY = 'UPDATE_SUBSIDIARY';
export const GET_SUBSIDIARY_DELETE = 'GET_SUBSIDIARY_DELETE';
export const DELETE_SUBSIDIARY = 'DELETE_SUBSIDIARY';
export const SET_SUBSIDIARY = 'SET_SUBSIDIARY';
export const GET_ALL_SUBSIDIARIES = 'GET_ALL_SUBSIDIARIES';
export const RESET_BRANCH = 'RESET_BRANCH';

// PRODUCTS
export const CREATE_PRODUCT = 'CREATE_PRODUCT';
export const GET_PRODUCTS = 'GET_PRODUCTS';
export const GET_PRODUCT_BY_ID = 'GET_PRODUCT_BY_ID';
export const RESET_PRODUCT_BY_ID = 'RESET_PRODUCT_BY_ID';
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
export const SAVE_ID_PRODUCT = 'SAVE_ID_PRODUCT';
export const GET_PRODUCT_DELETE = 'GET_PRODUCT_DELETE';
export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export const SAVE_IMAGE_PRODUCT_ID = 'SAVE_IMAGE_PRODUCT_ID';
export const GET_ALL_PRODUCTS = 'GET_ALL_PRODUCTS';
export const REJECT_PRODUCT = 'REJECT_PRODUCT';

//CABAIN
export const CREATE_CABAIN = 'CREATE_CABAIN';
export const GET_CABAINS = 'GET_CABAINS';
export const GET_CABAIN_BY_ID = 'GET_CABAIN_BY_ID';
export const UPDATE_CABAIN = 'UPDATE_CABAIN';
export const GET_CABAIN_DELETE = 'GET_CABAIN_DELETE';
export const DELETE_CABAIN = 'DELETE_CABAIN';

//CRUD PROVIDER
export const CREATE_PROVIDER = 'CREATE_PROVIDER';
export const GET_PROVIDERS = 'GET_PROVIDERS';
export const GET_PROVIDER_BY_ID = 'GET_PROVIDER_BY_ID';
export const UPDATE_PROVIDER = 'UPDATE_PROVIDER';
export const SAVE_ID_PROVIDER = 'SAVE_ID_PROVIDER';
export const GET_PROVIDER_DELETE = 'GET_PROVIDER_DELETE';
export const DELETE_PROVIDER = 'DELETE_PROVIDER';

// APPLIANCES
export const CREATE_APPLIANCE = 'CREATE_APPLIANCE';
export const GET_APPLIANCES = 'GET_APPLIANCES';
export const GET_APPLIANCE_BY_ID = 'GET_APPLIANCE_BY_ID';
export const UPDATE_APPLIANCE = 'UPDATE_APPLIANCE';
export const GET_APLIANCE_DELETE = 'GET_APLIANCE_DELETE';
export const DELETE_APPLIANCE = 'DELETE_APPLIANCE';

// BRANCH APPLIANCES
export const CREATE_BRANCH_APPLIANCE = 'CREATE_APPLIANCE';
export const GET_BRANCH_APPLIANCES = 'GET_APPLIANCES';
export const GET_BRANCH_APPLIANCE_BY_ID = 'GET_APPLIANCE_BY_ID';
export const UPDATE_BRANCH_APPLIANCE = 'UPDATE_APPLIANCE';
export const GET_BRANCH_APLIANCE_DELETE = 'GET_APLIANCE_DELETE';
export const DELETE_BRANCH_APPLIANCE = 'DELETE_APPLIANCE';

// AUTH
export const AUTH_SIGNIN = 'AUTH_SIGNIN';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_RESET_PASSWORD = 'AUTH_RESET_PASSWORD';
export const AUTH_CHANGE_PASSWORD = 'AUTH_CHANGE_PASSWORD';
export const AUTH_RESET_PIN = 'AUTH_RESET_PIN';
export const AUTH_EXPIRE_TOKEN = 'AUTH_EXPIRE_TOKEN';
export const AUTH_REFRESH_TOKEN = 'AUTH_REFRESH_TOKEN';
export const AUTH_FETCH_ERROR = 'AUTH_FETCH_ERROR';
export const AUTH_CHEKIN = 'AUTH_CHEKIN';
export const AUTH_LOGOUT_AWAY = 'AUTH_LOGOUT_AWAY';
export const AUTH_QUICK_LOGIN = 'AUTH_QUICK_LOGIN';

// QUICK_LOGIN
export const QUICK_LOGIN = 'QUICK_LOGIN';

// ERROR
export const FETCH_ERROR = 'FETCH_ERROR';

// CATEGORIES
export const CREATE_CATEGORY = 'CREATE_CATEGORY';
export const GET_CATEGORIES = 'GET_CATEGORIES';
export const GET_CATEGORY_BY_ID = 'GET_CATEGORY_BY_ID';
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY';
export const GET_CATEGORY_DELETE = 'GET_CATEGORY_DELETE';
export const DELETE_CATEGORY = 'DELETE_CATEGORY';

// CABAIN
export const LIST_CABAIN = 'LIST_CABAIN';

//JOB OPTIONS
export const GET_JOB_POSITIONS = 'GET_JOB_POSITIONS';

// DEPARTAMENTS
export const GET_DEPARTAMENTS = 'GET_DEPARTAMENTS';

// // KARDEX
export const INITIAL_DATA = 'INITIAL_DATA';
export const SAVE_USER = 'SAVE_USER';
export const SAVE_KARDEX = 'SAVE_KARDEX';
export const SAVE_ADDRESS = 'SAVE_ADDRESS';
export const SAVE_BANK_INFORMATION = 'SAVE_BANK_INFORMATION';
export const SAVE_CONTACT = 'SAVE_CONTACT';
export const SAVE_JOB_REFENCE = 'SAVE_JOB_REFENCE';
export const SAVE_PERSONAL_DATA = 'SAVE_PERSONAL_DATA';
export const SAVE_PHONE = 'SAVE_PHONE';
export const SAVE_SIGNATURE = 'SAVE_SIGNATURE';
export const SAVE_MEDICAL_INFO = 'SAVE_MEDICAL_INFO';
export const SAVE_USER_BY_ID = 'SAVE_USER_BY_ID';
export const CONTACTS_KARDEX_PERSIST = 'CONTACTS_KARDEX_PERSIST';
export const GET_ROLES = 'GET_ROLES';
export const REFRESH_KARDEX = 'REFRESH_KARDEX';
export const SAVE_IMAGE = 'SAVE_IMAGE';
export const SAVE_IMAGE_ID = 'SAVE_IMAGE_ID';
export const ONLY_FOR_DOCTOR = 'ONLY_FOR_DOCTOR';

// Sale Order
export const CREATE_SALE_ORDER = 'CREATE_SALE_ORDER';
export const GET_SALE_ORDERS = 'GET_SALE_ORDERS';
export const GET_SALE_ORDER_BY_ID = 'GET_SALE_ORDER_BY_ID';
export const UPDATE_SALE_ORDER = 'UPDATE_SALE_ORDER';
export const SAVE_ID_SALE_ORDER = 'SAVE_ID_SALE_ORDER';
export const GET_SALE_ORDER_DELETE = 'GET_SALE_ORDER_DELETE';
export const DELETE_SALE_ORDER = 'DELETE_SALE_ORDER';

// ProductTypes enum
export const GET_PRODUCT_TYPES = 'GET_PRODUCT_TYPES';

// TREATMENT
export const CREATE_TREATMENT = 'CREATE_TREATMENT';
export const GET_TREATMENTS = 'GET_TREATMENTS';
export const GET_TREATMENT_BY_ID = 'GET_TREATMENT_BY_ID';
export const UPDATE_TREATMENT = 'UPDATE_TREATMENT';
export const SAVE_ID_TREATMENT = 'SAVE_ID_TREATMENT';
export const GET_TREATMENT_DELETE = 'GET_TREATMENT_DELETE';
export const DELETE_TREATMENT = 'DELETE_TREATMENT';
export const SAVE_TREATMENT_IMAGE_ID = 'SAVE_TREATMENT_IMAGE_ID';

// TREATMENT_TYPE
export const GET_TREATMENT_TYPES = 'GET_TREATMENT_TYPES';
export const GET_TREATMENT_TYPE_BY_ID = 'GET_TREATMENT_TYPE_BY_ID';

// AREA
export const GET_AREAS = 'GET_AREAS';

// Crud Purchase Order
export const CREATE_PURCHASE_ORDER = 'CREATE_PURCHASE_ORDER';
export const GET_PURCHASE_ORDERS = 'GET_PURCHASE_ORDERS';
export const GET_PURCHASE_ORDER_BY_ID = 'GET_PURCHASE_ORDER_BY_ID';
export const UPDATE_PURCHASE_ORDER = 'UPDATE_PURCHASE_ORDER';
export const SAVE_ID_PURCHASE_ORDER = 'SAVE_ID_PURCHASE_ORDER';
export const GET_PURCHASE_ORDER_DELETE = 'GET_PURCHASE_ORDER_DELETE';
export const DELETE_PURCHASE_ORDER = 'DELETE_PURCHASE_ORDER';

// Crud Assortment Order
export const CREATE_ASSORTMENT_ORDER = 'CREATE_ASSORTMENT_ORDER';
export const GET_ASSORTMENT_ORDERS = 'GET_ASSORTMENT_ORDERS';
export const GET_ASSORTMENT_ORDER_BY_ID = 'GET_ASSORTMENT_ORDER_BY_ID';
export const UPDATE_ASSORTMENT_ORDER = 'UPDATE_ASSORTMENT_ORDER';
export const SAVE_ID_ASSORTMENT_ORDER = 'SAVE_ID_ASSORTMENT_ORDER';
export const GET_ASSORTMENT_ORDER_DELETE = 'GET_ASSORTMENT_ORDER_DELETE';
export const DELETE_ASSORTMENT_ORDER = 'DELETE_ASSORTMENT_ORDER';
export const APPROVE_ASSORTMENT_ORDER = 'APPROVE_ASSORTMENT_ORDER';

// Stock
export const GET_STOCK = 'GET_STOCK';
export const GET_STOCK_DETAILS = 'GET_STOCK_DETAILS';
export const STOCK_ADJUSTMENT = 'STOCK_ADJUSTMENT';
export const GET_STOCK_BY_BRANCH_ID = 'GET_STOCK_BY_BRANCH_ID';
export const CREATE_MAX_MIN = 'CREATE_MAX_MIN';
export const GET_MAX_MIN_BY_BRANCH = 'GET_MAX_MIN_BY_BRANCH';
export const UPDATE_MAX_MIN = 'UPDATE_MAX_MIN';
export const GET_STOCK_BY_BRANCH_AND_TREATMENT =
  'GET_STOCK_BY_BRANCH_AND_TREATMENT';

// User Refactor
export const CREATE_USER = 'CREATE_USER';
export const GET_USERS = 'GET_USERS';
export const GET_DOCTORS = 'GET_DOCTORS';
export const GET_COSMETOLOGISTS = 'GET_COSMETOLOGISTS';
export const GET_USER_BY_ID = 'GET_USER_BY_ID';
export const UPDATE_USER = 'UPDATE_USER';
export const SAVE_ID_USER = 'SAVE_ID_USER';
export const GET_USER_DELETE = 'GET_USER_DELETE';
export const DELETE_USER = 'DELETE_USER';
export const SAVE_IMAGE_USER_ID = 'SAVE_IMAGE_USER_ID';
export const GET_ALL_USERS = 'GET_ALL_USERS';

// Transfers
export const CREATE_TRANSFER = 'CREATE_TRANSFER';
export const GET_TRANSFERS = 'GET_TRANSFERS';
export const GET_TRANSFER_BY_ID = 'GET_TRANSFER_BY_ID';
export const APPROVE_TRANSFER = 'APPROVE_TRANSFER';
export const ACCEPT_TRANSFER = 'ACCEPT_TRANSFER';
export const GET_TRANSFER_DELETE = 'GET_TRANSFER_DELETE';
export const TRANSFER_DELETE = 'TRANSFER_DELETE';

// Roles
export const GET_ALL_ROLES = 'GET_ALL_ROLES';

// Job Positions
export const GET_ALL_JOB_POSITIONS = 'GET_ALL_JOB_POSITIONS';

// Departments
export const GET_ALL_DEPARTMENTS = 'GET_DEPARTMENTS';

// Logs
export const GET_ALL_LOGS = 'GET_ALL_LOGS';

//Consents
export const GET_ALL_CONSENTS = 'GET_ALL_CONSENTS';

// CUSTOMERS
export const CREATE_CUSTOMER = 'CREATE_CUSTOMER';
export const GET_CUSTOMERS = 'GET_CUSTOMERS';
export const GET_CUSTOMER_BY_ID = 'GET_CUSTOMER_BY_ID';
export const UPDATE_CUSTOMER = 'UPDATE_CUSTOMER';
export const GET_CUSTOMER_DELETE = 'GET_CUSTOMER_DELETE';
export const DELETE_CUSTOMER = 'DELETE_CUSTOMER';
export const GET_CUSTOMERS_ORDERS = 'GET_CUSTOMERS_ORDERS';
export const GET_CUSTOMERS_TOTAL_PURCHASE = 'GET_CUSTOMERS_TOTAL_PURCHASE';
export const GET_TUTORS = 'GET_TUTORS';
export const RESET_CUSTOMER = 'RESET_CUSTOMER';

// PACKAGE
export const CREATE_PACKAGE = 'CREATE_PACKAGE';
export const GET_PACKAGES = 'GET_PACKAGES';
export const GET_PACKAGE_BY_ID = 'GET_PACKAGE_BY_ID';
export const UPDATE_PACKAGE = 'UPDATE_PACKAGE';
export const GET_PACKAGE_DELETE = 'GET_PACKAGE_DELETE';
export const DELETE_PACKAGE = 'DELETE_PACKAGE';
export const SAVE_PACKAGE_IMAGE_ID = 'SAVE_PACKAGE_IMAGE_ID';

// LOCK
export const CREATE_LOCK = 'CREATE_LOCK';
export const GET_LOCKS = 'GET_LOCKS';
export const GET_LOCK_BY_ID = 'GET_LOCK_BY_ID';
export const UPDATE_LOCK = 'UPDATE_LOCK';
export const GET_LOCK_DELETE = 'GET_LOCK_DELETE';
export const DELETE_LOCK = 'DELETE_LOCK';
// AGENDA
export const CREATE_APPOINTMENT = 'CREATE_APPOINTMENT';
export const GET_APPOINTMENTS = 'GET_APPOINTMENTS';
export const GET_APPOINTMENT_BY_ID = 'GET_APPOINTMENT_BY_ID';
export const CLEAR_APPOINTMENT_BY_ID = 'CLEAR_APPOINTMENT_BY_ID';
export const UPDATE_APPOINTMENT = 'UPDATE_APPOINTMENT';
export const GET_APPOINTMENT_CANCEL = 'GET_APPOINTMENT_CANCEL';
export const CANCEL_APPOINTMENT = 'CANCEL_APPOINTMENT';
export const SET_ON_PROCESS = 'SET_ON_PROCESS';
export const CONFIRM_APPOINTMENT = 'CONFIRM_APPOINTMENT';
export const SET_BRANCH = 'SET_BRANCH';
export const GET_APPOINTMENT_BY_CUSTOMER = 'GET_APPOINTMENT_BY_CUSTOMER';

// NOTE
export const CREATE_NOTE = 'CREATE_NOTE';
export const GET_NOTES = 'GET_NOTES';
export const GET_NOTE_BY_ID = 'GET_NOTE_BY_ID';
export const UPDATE_NOTE = 'UPDATE_NOTE';
export const GET_NOTE_DELETE = 'GET_NOTE_DELETE';
export const DELETE_NOTE = 'DELETE_NOTE';
export const SAVE_NOTE_IMAGE_ID = 'SAVE_NOTE_IMAGE_ID';

// DIARY
export const GET_ALL_DIARIES = 'GET_ALL_DIARIES';

// MODULES
export const GET_ALL_MODULES = 'GET_ALL_MODULES';

// GENERAL DATA
export const SET_APPOINTMENT_DATA = 'SET_APPOINTMENT_DATA';
export const SET_FILTERS_DATA = 'SET_FILTERS_DATA';
export const CLEAR_FILTERS_DATA = 'CLEAR_FILTERS_DATA';
export const DATA_DELETED = 'DATA_DELETED';

// CONFIGS
export const GET_CONFIGS = 'GET_CONFIGS';
export const UPDATE_CONFIGS = 'UPDATE_CONFIGS';

// Loadings
export const SHOW_LOADING = 'SHOW_LOADING';
export const HIDE_LOADING = 'HIDE_LOADING';

// Sales
export const GET_ALL_BRANCH_SALES = 'GET_ALL_BRANCH_SALES';
export const GET_ALL_CASH_SALES = 'GET_ALL_CASH_SALES';
export const GET_CASH_SALE_BY_ID = 'GET_CASH_SALE_BY_ID';
export const GET_ALL_SALES = 'GET_ALL_SALES';
export const UPDATE_SELLER = 'UPDATE_SELLER';
export const REFUND_SALE = 'REFUND_SALE';

// Commissions
export const GET_MEDICAL_COMMISSIONS = 'GET_MEDICAL_COMMISSIONS';
export const GET_MEDICAL_COMMISSION_BY_USER = 'GET_MEDICAL_COMMISSION_BY_USER';
export const GET_COSMETOLOGY_COMMISSION_BY_USER =
  'GET_COSMETOLOGY_COMMISSION_BY_USER';
export const GET_COSMETOLOGY_COMMISSIONS = 'GET_COSMETOLOGY_COMMISSIONS';
export const UPDATE_COMMISSION_AGENT = 'UPDATE_COMMISSION_AGENT';

//DAYLY CASH CLOSING
export const GET_ALL_DAILY_CASH_CLOSING = 'GET_ALL_DAILY_CASH_CLOSING';
export const GET_ALL_DAILY_CASH_CLOSING_BY_BRANCH =
  'GET_ALL_DAILY_CASH_CLOSING_BY_BRANCH';
export const GET_ALL_DAILY_CASH_CLOSING_CASH =
  'GET_ALL_DAILY_CASH_CLOSING_CASH';
export const GET_ALL_DAILY_CASH_CLOSING_CASH_BY_BRANCH =
  'GET_ALL_DAILY_CASH_CLOSING_CASH_BY_BRANCH';

// MISSED APPOINTENT
export const GET_MISSED_APPOINTMENTS = 'GET_MISSED_APPOINTMENTS';
export const CLEAR_MISSED_APPOINTMENTS = 'CLEAR_MISSED_APPOINTMENTS';
export const FINISH_APPOINTMENT = 'FINISH_APPOINTMENT';

// PURCHASED PRODUCT
export const GET_PURCHASED_PRODUCT_BY_ID = 'GET_PURCHASED_PRODUCT_BY_ID,';
export const UPDATE_PURCHASED_PRODUCT = 'UPDATE_PURCHASED_PRODUCT';
export const GET_PURCHASED_PRODUCT_BY_CUSTOMER_ID =
  'GET_PURCHASED_PRODUCT_BY_CUSTOMER_ID';
// COUPONS
export const CREATE_COUPON = 'CREATE_COUPON';
export const GET_COUPONS = 'GET_COUPONS';
export const GET_COUPON_BY_ID = 'GET_COUPON_BY_ID';
export const UPDATE_COUPON = 'UPDATE_COUPON';
export const SAVE_ID_COUPON = 'SAVE_ID_COUPON';
export const GET_COUPON_DELETE = 'GET_COUPON_DELETE';
export const DELETE_COUPON = 'DELETE_COUPON';
export const FINISH_PURCHASED_PRODUCT = 'FINISH_PURCHASED_PRODUCT';

// Reports
export const GET_STOCK_BY_BRANCH = 'GET_STOCK_BY_BRANCH';
export const GET_EXPENSES_BY_BRANCH = 'GET_EXPENSES_BY_BRANCH';
export const GET_GENERAL_INVENTORY = 'GET_GENERAL_INVENTORY';
export const GET_GENERAL_EXPENSES = 'GET_GENERAL_EXPENSES';
export const GET_PROFITABILITY_BY_BRANCH = 'GET_PROFITABILITY_BY_BRANCH';
export const GET_OCCUPANCY_PERCENTAGE_BY_BRANCH =
  'GET_OCCUPANCY_PERCENTAGE_BY_BRANCH';
export const GET_GENERAL_CUSTOMER = 'GET_GENERAL_CUSTOMER';
export const GET_GENERAL_APPOINTMENTS = 'GET_GENERAL_APPOINTMENTS';
export const GET_GENERAL_COSMETOLOGIC_FEES = 'GET_GENERAL_COSMETOLOGIC_FEES';
export const GET_COSMETOLOGIC_FEE_BY_COSMETOLOGIST =
  'GET_COSMETOLOGIC_FEE_BY_COSMETOLOGIST';

export const GET_MEDICAL_COMMISSIONS_BY_BRANCH =
  'GET_MEDICAL_COMMISSIONS_BY_BRANCH';
export const GET_SCHEDULED_APPOINTMENTS = 'GET_SCHEDULED_APPOINTMENTS';
export const GET_INDIVIDUAL_COMMISSION_MEDICAL =
  'GET_INDIVIDUAL_COMMISSION_MEDICAL';
export const GET_DAILY_SALES = 'GET_DAILY_SALES';
export const GET_MONTHLY_SALES = 'GET_MONTHLY_SALES';
export const GET_DAILY_CASH_CLOSING = 'GET_DAILY_CASH_CLOSING';
